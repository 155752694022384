import classNames from "classnames";
import { AllHtmlEntities } from "html-entities";
import moment from "moment-mini";
import React from "react";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import config from "../../../../../config";
import withAnalysisCategories from "../../../../../hocs/withAnalysisCategories";
import withHistoryListItemAccessHandler from "../../../../../hocs/withHistoryListItemAccessHandler";
import withUserData from "../../../../../hocs/withUserData";
import stripHtmlTags from "../../../../../lib/stripHtmlTags";
import ContentSection from "../../ContentSection";
import SimpleAlertButton from "../../SimpleAlertButton/modules/default";
import SquaberButton from "../../SquaberButton";
import SquaberLink from "../../SquaberLink";
import SquaberTooltip from "../../SquaberTooltip";

const SquaberCommentsListItem = ({
  translate,
  stock: { ticker, market, name: stock_name, id: stockId },
  author,
  active,
  created,
  updated,
  content,
  permissions,
  hasPermission,
  handleDescriptionButtonClick,
  locale,
  id,
  category,
  analysisCategories
}) => {
  const entities = new AllHtmlEntities();

  let transformedContent = entities.decode(
    stripHtmlTags(content, { allowedTags: [] })
  );

  const maxLength = config.squaberCommentDescriptionMaxLength;

  if (transformedContent.length > maxLength) {
    transformedContent = transformedContent.substring(0, maxLength) + "...";
  }

  const commentCategory = analysisCategories.find(cat => cat.id === category);

  const limitedAccess = active && content === null;

  const shouldShowContent = !limitedAccess || hasPermission(permissions);

  return (
    <ContentSection className="history-list-item">
      <div className="history-list-item__row">
        <div className="basic-info">
          <SquaberLink
            to={UrlProvider.getUrl("fe.stockPage", {
              locale,
              marketId: market,
              stockId: ticker
            })}
            className={"stock-ticker-wrapper"}
          >
            <h2 className="stock-ticker">{ticker}</h2>
            <span className="stock-market">{market}</span>
          </SquaberLink>
          <p className="stock-name">{stock_name}</p>
        </div>
        <div className="alert-status">
          <SimpleAlertButton
            stockId={stockId}
            ticker={ticker}
            market={market}
          />
        </div>
      </div>
      <div className="history-list-item__row">
        <div className="basic-info">
          <div className="secondary-text">
            {translate("Created on")}:{" "}
            {moment(created).format("DD.MM.YYYY, HH:mm")}
          </div>
        </div>
        <div className="alert-status">
          <div className="secondary-text">
            {translate("Status")}:{" "}
            <span
              className={classNames({
                "signal-status-description": true,
                [`signal-${active ? "active" : "inactive"}`]: true
              })}
            >
              {translate(`signal_status_${active ? "active" : "inactive"}`)}
            </span>
          </div>
        </div>
      </div>
      <div className="history-list-item__row comment-summary">
        <div
          className={classNames({
            "limited-access": !shouldShowContent
          })}
          dangerouslySetInnerHTML={{
            __html: shouldShowContent
              ? transformedContent
              : new Array(100).fill("x").join("")
          }}
        />
      </div>
      <div className="history-list-item__footer">
        <div className="author-data">
          <p className="secondary-text multiline description">
            {translate("Author")}: {author.first_name}&nbsp;{author.last_name}
          </p>
          <p className="secondary-text">{commentCategory?.name}</p>
          {updated !== created ? (
            <p className="secondary-text multiline">
              {translate("Updated on")}:{" "}
              {moment(updated).format("DD.MM.YYYY HH:mm")}
            </p>
          ) : null}
        </div>
        <div className="button-wrapper">
          <SquaberTooltip
            title={
              !shouldShowContent
                ? "This is the content available in the premium Squaber version. Use one of our bundles to get access to it"
                : ""
            }
          >
            <SquaberLink
              to={
                shouldShowContent
                  ? UrlProvider.getUrl("fe.singleAnalysisPage", {
                      locale,
                      id
                    })
                  : "#!"
              }
              onClick={event => {
                handleDescriptionButtonClick(hasAccess => {
                  if (!hasAccess) {
                    event.preventDefault();
                  }
                }, limitedAccess);
              }}
            >
              <SquaberButton
                color="primary_small"
                disabled={!shouldShowContent}
              >
                {translate("Description")}
              </SquaberButton>
            </SquaberLink>
          </SquaberTooltip>
        </div>
      </div>
    </ContentSection>
  );
};

export default compose(
  withUserData,
  withHistoryListItemAccessHandler,
  withAnalysisCategories
)(SquaberCommentsListItem);
