import React from "react";
import { bindPromiseCreators } from "redux-saga-routines";

import { connect } from "../i18n/connect";
import accessLevelsMap from "../lib/access/accessLevelsMap";
import getAccessLevel from "../lib/access/getAccessLevel";
import { actions as authActions } from "../redux/modules/auth/login";
import {
  getAuthToken,
  getUserData,
  getUserID,
  getUserWasLoggedIn
} from "../redux/modules/auth/login/selector";
import { handleInsufficientPermissionsRoutinePromiseCreator } from "../redux/modules/auth/userPermissions";
import {
  getCanDisplaySection,
  getHasAccess,
  getHasPermission
} from "../redux/modules/auth/userPermissions/selector";

export default function withUserData(WrappedComponent) {
  const mapStateToProps = state => ({
    userID: getUserID(state),
    userData: getUserData(state),
    authToken: getAuthToken(state),
    userWasLoggedIn: getUserWasLoggedIn(state),
    hasAccess: getHasAccess(state),
    hasPermission: getHasPermission(state),
    canDisplaySection: getCanDisplaySection(state)
  });

  const mapDispatchToProps = {
    logout: authActions.logout,
    refreshUserData: authActions.refreshUserData,
    toggleMarketingEmails: authActions.toggleMarketingEmails
  };

  const promiseMapDispatchToProps = dispatch => ({
    ...bindPromiseCreators(
      {
        refreshUserDataPromiseCreator: authActions.refreshUserDataPromiseCreator
      },
      dispatch
    ),
    ...bindPromiseCreators(
      {
        handleInsufficientPermissionsAsync: handleInsufficientPermissionsRoutinePromiseCreator
      },
      dispatch
    )
  });

  @connect(mapStateToProps, mapDispatchToProps)
  @connect(null, promiseMapDispatchToProps)
  class WithUserData extends React.Component {
    render() {
      const {
        userData,
        hasAccess,
        canDisplaySection,
        handleInsufficientPermissionsAsync,
        hasPermission
      } = this.props;

      const accessLevel = getAccessLevel(userData, {});
      const userHasAccess = hasAccess(accessLevelsMap.HAS_PREMIUM_ACCESS);
      const userIsNotLoggedIn = hasAccess(accessLevelsMap.NOT_LOGGED_IN);

      return (
        <WrappedComponent
          {...this.props}
          userAccessLevel={accessLevel}
          userHasAccess={userHasAccess}
          hasPermission={hasPermission}
          userIsNotLoggedIn={userIsNotLoggedIn}
          canDisplaySection={canDisplaySection}
          handleInsufficientPermissionsAsync={
            handleInsufficientPermissionsAsync
          }
        />
      );
    }
  }

  return WithUserData;
}
