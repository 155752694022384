import { handleActions } from "redux-actions";
import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const handleInsufficientPermissionsRoutine = createRoutine(
  "app/auth/userPermissions/HANDLE_INSUFFICIENT_PERMISSIONS"
);

export const handleInsufficientPermissionsRoutinePromiseCreator = promisifyRoutine(
  handleInsufficientPermissionsRoutine
);

const initialState = {
  insufficientPermissions: {
    data: null,
    loading: null,
    error: null
  }
};

export default handleActions(
  {
    [handleInsufficientPermissionsRoutine.TRIGGER]: state => ({
      ...state,
      insufficientPermissions: {
        ...state.insufficientPermissions,
        loading: true,
        error: null
      }
    }),
    [handleInsufficientPermissionsRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      insufficientPermissions: {
        ...state.insufficientPermissions,
        loading: false,
        data: payload,
        error: null
      }
    }),
    [handleInsufficientPermissionsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      insufficientPermissions: {
        ...state.insufficientPermissions,
        error: payload
      }
    })
  },
  initialState
);
