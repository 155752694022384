import autoBind from "auto-bind";
import Formsy from "formsy-react";
import React from "react";

import withTranslations from "../../../../../../../hocs/withTranslations";
import ComposedTextField from "../../../../../../../lib/FormBuilder/FormsyMaterialUI/ComposedTextField";
import ApiHelper from "../../../../../../../lib/UniversalTable/CoreHelpers/ApiHelper";
import SettingsComponentPropTypes from "../../../../../../../lib/UniversalTable/SettingsComponentPropTypes";

@withTranslations
class Search extends React.Component {
  static propTypes = SettingsComponentPropTypes;

  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  setSearch(attribute, value) {
    const {
      settingsState: { search }
    } = this.props;

    let newSearch = { ...search };

    delete newSearch[attribute];

    if (value && value.length) {
      newSearch[attribute] = value;
    }

    this.props.setSettingsState({
      search: newSearch
    });
  }

  render() {
    const { columns, settingsState, hasPermission } = this.props;

    return (
      <Formsy>
        <div className="filters-wrapper">
          {columns
            .filter(column => ApiHelper.getIsSearchable(column))
            .filter(column =>
              ApiHelper.getUserHasPermissionToColumn(column, hasPermission)
            )
            .map(column => (
              <div
                className="filters-item-wrapper half-width"
                key={ApiHelper.getColumnAttributeForFiltering(column)}
              >
                <ComposedTextField
                  name={ApiHelper.getColumnAttributeForFiltering(column)}
                  label={column.label}
                  value={
                    settingsState.search[
                      ApiHelper.getColumnAttributeForFiltering(column)
                    ] || ""
                  }
                  onChange={value => {
                    this.setSearch(
                      ApiHelper.getColumnAttributeForFiltering(column),
                      value
                    );
                  }}
                  fullWidth
                />
              </div>
            ))}
        </div>
      </Formsy>
    );
  }
}

export default Search;
