const canAccessLink = ({ link, userData, hasPermission }) => {
  const {
    show_for_anonymous,
    show_for_freemium,
    show_for_premium,
    permissions
  } = link ?? {};

  let canShow;

  if (!userData) {
    canShow = show_for_anonymous;
  } else if (!hasPermission(permissions)) {
    canShow = show_for_freemium;
  } else {
    canShow = show_for_premium;
  }

  return canShow;
};

export default canAccessLink;
