import accessLevelsMap from "../../../../lib/access/accessLevelsMap";
import getAccessLevel from "../../../../lib/access/getAccessLevel";
import { NOTIFICATION_TYPES } from "../../../../lib/getStockPageSectionFromNotificationType";
import { getUserData } from "../login/selector";

export const PLAN_PERMISSIONS_NAMES = {
  PLATINUM_TYPES: "plans.platinum_types",
  SIGNALS: "plans.signals",
  COMMENTS_AND_ANALYSES: "plans.comments_and_analyses",
  FUNDAMENTAL_ANALYSES: "plans.fundamental_analyses",
  MORNING_STOCK_BRIEFS: "plans.morning_stock_briefs",
  WEBINARS_BY_MICHAL_AND_MARCIN: "plans.webinars_by_michal_and_marcin",
  MARCIN_VIDEOS_YT: "plans.marcin_videos_yt",
  PRICE_ALERTS: "plans.price_alerts",
  NUMBER_OF_ALERTED_STOCKS: "plans.number_of_alerted_stocks",
  NUMBER_OF_PORTFOLIOS: "plans.number_of_portfolios",
  PORTFOLIO: "plans.portfolio",
  STOCK_TAGS: "plans.stock_tags",
  THIRD_PARTY_OFFERING: "plans.third_party_offering",
  CHART_EDITING: "plans.chart_editing",
  SUPPLY_DEMAND: "plans.supply_demand",
  TURTLES_GROUP_STOCK: "plans.turtles_group_stock",
  MMA_TREND_STRATEGIES: "plans.mma_trend_strategies",
  OPS_OPD_TREND_STRATEGIES: "plans.ops_opd_trend_strategies",
  SAXO_BANK_DISCOUNT: "plans.saxo_bank_discount",
  ALIOR_BANK_DISCOUNT: "plans.alior_bank_discount",
  FINANCIAL_AND_TECHNICAL_MANUAL_STRATEGY:
    "plans.financial_and_technical_manual_strategy",
  TECHNICAL_MANUAL_STRATEGY: "plans.technical_manual_strategy",
  LONG_TERM_SIGNALS: "plans.long_term_signals",
  OTHER_DISCOUNTS: "plans.other_discounts",
  COMPANY_ANALYSIS_REQUESTS: "plans.company_analysis_requests",
  FREEMIUM_LIVE_WEBINARS: "plans.freemium_live_webinars",
  PREMIUM_LIVE_WEBINARS: "plans.premium_live_webinars",
  WEBINAR_RECORDINGS: "plans.webinar_recordings",
  FREEMIUM_EDUCATION: "plans.freemium_education",
  PREMIUM_EDUCATION: "plans.premium_education",
  MEDIA_MONITOR_EBI_ESPI: "plans.media_monitor_ebi_espi",
  MEDIA_MONITOR_PAP: "plans.media_monitor_pap",
  SAVING_ANALYSES_AND_TEMPLATES: "plans.saving_analyses_and_templates",
  TRADING_VIEW_CHART: "plans.trading_view_chart",
  FINANCIAL_SITUATION: "plans.financial_situation",
  FINANCIAL_INDICATORS: "plans.financial_indicators",
  NOTICES: "plans.plan_notices"
};

export const PLAN_PERMISSIONS_SECTIONS = {
  PRICE_ALERTS: [PLAN_PERMISSIONS_NAMES.PRICE_ALERTS],
  SIGNALS: [
    PLAN_PERMISSIONS_NAMES.SIGNALS,
    PLAN_PERMISSIONS_NAMES.LONG_TERM_SIGNALS
  ],
  FUNDAMENTAL_ANALYSES: [PLAN_PERMISSIONS_NAMES.FUNDAMENTAL_ANALYSES],
  FINANCIAL_INDICATORS: [PLAN_PERMISSIONS_NAMES.FINANCIAL_INDICATORS],
  ANALYSIS_CATEGORIES: [PLAN_PERMISSIONS_NAMES.TURTLES_GROUP_STOCK],
  USER_NOTES: [PLAN_PERMISSIONS_NAMES.NOTICES],
  INDEX_COMPONENTS: [], //TODO: Add permission
  SECTION_HISTORY_WITH_STOCK: [PLAN_PERMISSIONS_NAMES.NUMBER_OF_PORTFOLIOS],
  SECTION_HISTORY_ALERT_MANAGER: [PLAN_PERMISSIONS_NAMES.PRICE_ALERTS],
  MEDIA_MONITOR: [
    PLAN_PERMISSIONS_NAMES.MEDIA_MONITOR_EBI_ESPI,
    PLAN_PERMISSIONS_NAMES.MEDIA_MONITOR_PAP
  ],
  TEMPLATES_AND_ANALYTICS: [
    PLAN_PERMISSIONS_NAMES.SAVING_ANALYSES_AND_TEMPLATES,
    PLAN_PERMISSIONS_NAMES.TRADING_VIEW_CHART
  ],
  PORTFOLIOS: [PLAN_PERMISSIONS_NAMES.NUMBER_OF_PORTFOLIOS],
  TRANSACTION_STRATEGY: [
    PLAN_PERMISSIONS_NAMES.MMA_TREND_STRATEGIES,
    PLAN_PERMISSIONS_NAMES.OPS_OPD_TREND_STRATEGIES,
    PLAN_PERMISSIONS_NAMES.FINANCIAL_AND_TECHNICAL_MANUAL_STRATEGY,
    PLAN_PERMISSIONS_NAMES.TECHNICAL_MANUAL_STRATEGY
  ]
};

export const PLAN_PERMISSIONS_NOTIFICATIONS = {
  [NOTIFICATION_TYPES.PRICE_ALERTS]: [PLAN_PERMISSIONS_NAMES.PRICE_ALERTS]
};

export const getHasAccess = state => permission => {
  const userData = getUserData(state);

  const accessLevel = getAccessLevel(userData, {});

  switch (permission) {
    case accessLevelsMap.HAS_PREMIUM_ACCESS: {
      return accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS;
    }

    case accessLevelsMap.NOT_LOGGED_IN: {
      return accessLevel === accessLevelsMap.NOT_LOGGED_IN;
    }
  }
};

export const getCanDisplaySection = state => (
  planPermissionsSections: String[] | String
) => {
  if (Array.isArray(planPermissionsSections)) {
    return getHasPermission(state)(planPermissionsSections);
  }

  if (typeof planPermissionsSections === "string") {
    return getHasPermission(state)([planPermissionsSections]);
  }
};

export const getCanDisplayNotificationType = state => (
  notificationType: String[]
) => {
  const sectionPermissions =
    PLAN_PERMISSIONS_NOTIFICATIONS?.[notificationType] ?? [];

  return getHasPermission(state)(sectionPermissions);
};

export const getHasPermission = state => (permissions = []) => {
  const userData = getUserData(state);

  const userPermissions = [...(userData?.permissions ?? [])] ?? [];

  return (permissions ?? []).every(permission =>
    userPermissions.includes(permission)
  );
};
