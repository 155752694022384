import React from "react";

import withUserData from "../../../../../hocs/withUserData";
import { PLAN_PERMISSIONS_SECTIONS } from "../../../../../redux/modules/auth/userPermissions/selector";
import LongTermSignalsList from "../../../common/LongTermSignalsList";
import NoAccessDisclaimer from "../../../common/NoAccessDisclaimer";
import PageTitle from "../../../common/PageTitle/modules/default";

const LongTermSignalPage = ({ translate, canDisplaySection }) => {
  return (
    <div className="history-list-page">
      <PageTitle title={translate("long_term_signals_header")} />
      <div className="heading-wrapper">
        <div className="heading">
          <h1>{translate("long_term_signals_header")}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: translate("long_term_signals_description")
            }}
          />
        </div>
      </div>
      {!canDisplaySection(PLAN_PERMISSIONS_SECTIONS.SIGNALS) ? (
        <NoAccessDisclaimer />
      ) : (
        <LongTermSignalsList />
      )}
    </div>
  );
};

export default withUserData(LongTermSignalPage);
