import React from "react";

import { connect } from "../i18n/connect";
import accessLevelsMap from "../lib/access/accessLevelsMap";
import getAccessLevel from "../lib/access/getAccessLevel";
import {
  getAuthToken,
  getUserData
} from "../redux/modules/auth/login/selector";
import {
  getCanDisplaySection,
  getHasAccess,
  getHasPermission
} from "../redux/modules/auth/userPermissions/selector";

export default function withUserDataGetter(WrappedComponent) {
  const mapStateToProps = state => ({
    userData: getUserData(state),
    authToken: getAuthToken(state),
    hasAccess: getHasAccess(state),
    hasPermission: getHasPermission(state),
    canDisplaySection: getCanDisplaySection(state)
  });

  @connect(mapStateToProps)
  class WithUserDataGetter extends React.Component {
    render() {
      const {
        userData,
        hasAccess,
        canDisplaySection,
        hasPermission
      } = this.props;

      const accessLevel = getAccessLevel(userData, {});
      const userHasAccess = hasAccess(accessLevelsMap.HAS_PREMIUM_ACCESS);
      const userIsNotLoggedIn = hasAccess(accessLevelsMap.NOT_LOGGED_IN);

      return (
        <WrappedComponent
          {...this.props}
          accessLevel={accessLevel}
          userHasAccess={userHasAccess}
          hasPermission={hasPermission}
          userIsNotLoggedIn={userIsNotLoggedIn}
          canDisplaySection={canDisplaySection}
        />
      );
    }
  }

  return WithUserDataGetter;
}
