import "../styles/DesktopStocksGridItem.scss";

import classNames from "classnames";
import moment from "moment-mini";
import React from "react";

import { UrlProvider } from "../../../../../api/UrlProvider";
import Price from "../../../../../common/Price";
import withLatestMediaMonitorEntry from "../../../../../hocs/withLatestMediaMonitorEntry";
import withMarketTransltaions from "../../../../../hocs/withMarketTranslations";
import withQuoteData from "../../../../../hocs/withQuoteData";
import withSimpleChartData from "../../../../../hocs/withSimpleChartData";
import withUserDataGetter from "../../../../../hocs/withUserDataGetter";
import getDesktopItemDetailsBoxes from "../../../../../lib/getDesktopItemDetailsBoxes";
import { getTranslatedMarket } from "../../../../../lib/getTranslatedMarket";
import MediaMonitorHelper from "../../../../../lib/MediaMonitor/MediaMonitorHelper";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import { PLAN_PERMISSIONS_SECTIONS } from "../../../../../redux/modules/auth/userPermissions/selector";
import ChangePercent from "../../ChangePercent";
import ContentSection from "../../ContentSection";
import CustomSimpleChart from "../../CustomSimpleChart/modules/default";
import SimpleAlertButton from "../../SimpleAlertButton/modules/default";
import SquaberLink from "../../SquaberLink/";

const stockIdExtractor = props => props.stock_id;

@withUserDataGetter
@withQuoteData()
@withSimpleChartData(stockIdExtractor)
@withLatestMediaMonitorEntry
@withMarketTransltaions
class DesktopStocksGridItem extends React.Component {
  render() {
    const {
      market,
      ticker,
      currency = "CUR",
      company_name,
      quoteData,
      translate,
      locale,
      advancedView,
      latestMediaMonitorEntry,
      is_index,
      canDisplaySection,
      userIsNotLoggedIn,
      stock_id,
      marketTranslations
    } = this.props;

    const {
      priceChangeBoxes,
      financialStatsBoxes
    } = getDesktopItemDetailsBoxes(this.props);

    const translatedMarket = getTranslatedMarket(
      locale,
      market,
      marketTranslations
    );
    return (
      <ContentSection className="desktop-stocks-grid-item">
        <div className="desktop-stocks-grid-item__head">
          <div className="desktop-stocks-grid-item__stock-basic-info">
            <SquaberLink
              to={UrlProvider.getUrl("fe.stockPage", {
                locale,
                marketId: translatedMarket,
                stockId: ticker
              })}
              className="ticker"
            >
              {ticker}
            </SquaberLink>
            {quoteData ? (
              <div className="quote-data">
                <div className="change">
                  <ChangePercent change={quoteData.change} />
                </div>
                <div className="current-price">
                  <Price value={quoteData.close} currency={currency} />
                </div>
              </div>
            ) : null}
          </div>
          <SimpleAlertButton
            className={"desktop-stocks-grid-item__alert-status"}
            stockId={stockIdExtractor(this.props)}
            ticker={ticker}
            market={translatedMarket}
          />
        </div>
        <div className="desktop-stocks-grid-item__additional-info">
          <div className="stock-name">{company_name}</div>
          {quoteData ? (
            <div className="quote-datetime">
              {translate("Quotation of")}:{" "}
              {moment(quoteData.datetime_utc)
                .utc()
                .add(2, "hours")
                .format("DD-MM-YYYY")}
            </div>
          ) : null}
        </div>
        <CustomSimpleChart
          showVolumeStudy={true}
          stockId={stock_id}
          showRedirectToFullChart={true}
          quoteData={quoteData}
          ticker={ticker}
          locale={locale}
          market={translatedMarket}
          showAdditionalChartButtons={true}
        />
        {advancedView ? (
          <div className="desktop-stocks-grid-item__advanced-view">
            <div className="advanced-view-section media-monitory-entry">
              <div className="media-monitor-entry-heading">
                <h5>{translate("Media monitor")}</h5>
                {latestMediaMonitorEntry ? (
                  <div className="see-more-media-monitor-entries">
                    <SquaberLink
                      to={UrlProvider.getUrl(
                        "fe.stockPageWithScrollToSection",
                        {
                          locale,
                          marketId: translatedMarket,
                          stockId: ticker,
                          section: "section-media-monitor"
                        }
                      )}
                    >
                      {translate("see all")}
                    </SquaberLink>
                  </div>
                ) : null}
              </div>
              {latestMediaMonitorEntry ? (
                <React.Fragment>
                  <div className="media-monitor-entry-date">
                    {moment(latestMediaMonitorEntry.pub_datetime).format(
                      "hh:mm, DD.MM.YYYY"
                    )}
                    , {latestMediaMonitorEntry.source}
                  </div>
                  <div className="media-monitor-entry-title">
                    {latestMediaMonitorEntry.title} [
                    <SquaberLink
                      to={latestMediaMonitorEntry.link || "#!"}
                      target="_blank"
                      onClick={() => {
                        const {
                          description,
                          provider,
                          attachments,
                          is_premium
                        } = latestMediaMonitorEntry;

                        let userHasAccessToContent = true;

                        if (is_premium) {
                          userHasAccessToContent = canDisplaySection(
                            PLAN_PERMISSIONS_SECTIONS.MEDIA_MONITOR
                          );
                        }

                        if (userHasAccessToContent) {
                          if (description) {
                            const helper = new MediaMonitorHelper(
                              description,
                              provider,
                              translate,
                              attachments
                            );

                            if (latestMediaMonitorEntry.description) {
                              showModal("InterestingStockDescription", {
                                title: latestMediaMonitorEntry.title,
                                content: helper.formatMediaMonitorEntry()
                              });
                            }
                          }
                        } else if (userIsNotLoggedIn) {
                          showModal("RegisterModal");
                        } else {
                          showModal("PremiumModal", {
                            name: "open interesting stock description attempt"
                          });
                        }
                      }}
                    >
                      {translate("More")}
                    </SquaberLink>
                    ]
                  </div>
                </React.Fragment>
              ) : (
                <div className="no-media-monitor-entries">
                  {translate(
                    "There are no media monitor entries for this stock"
                  )}
                </div>
              )}
            </div>
            <div className="advanced-view-section after-media-monitor">
              <div className="advanced-view-boxes-list">
                {priceChangeBoxes.map(priceChangeBox => (
                  <div className="advanced-view-box" key={priceChangeBox.label}>
                    <div className="label">{priceChangeBox.label}</div>
                    <div className="value">
                      {priceChangeBox.value !== null &&
                      typeof priceChangeBox.value !== "undefined" ? (
                        <span>{Number(priceChangeBox.value).toFixed(2)}%</span>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="advanced-view-section">
              <div
                className={classNames({
                  "advanced-view-boxes-list": true,
                  "has-6-elements": !is_index,
                  "has-3-elements": is_index
                })}
              >
                {financialStatsBoxes.map(financialStatsBox => (
                  <div
                    className="advanced-view-box"
                    key={financialStatsBox.label}
                  >
                    <div className="label">{financialStatsBox.label}</div>
                    {financialStatsBox.label2 ? (
                      <div className="label-2">{financialStatsBox.label2}</div>
                    ) : null}
                    <div className="value">
                      {financialStatsBox.value}
                      {financialStatsBox.value2 ? (
                        <React.Fragment>
                          {" "}
                          / {financialStatsBox.value2}
                        </React.Fragment>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </ContentSection>
    );
  }
}

export default DesktopStocksGridItem;
