import { fork, put, takeLatest } from "redux-saga/effects";

import { handleInsufficientPermissionsRoutine } from "./index";

function* onHandleInsufficientPermissions({ payload }) {
  try {
    yield put(handleInsufficientPermissionsRoutine.success());
  } catch (e) {
    yield put(handleInsufficientPermissionsRoutine.failure(e));
  }
}

function* watchUserPermissionsActions() {
  yield takeLatest(
    handleInsufficientPermissionsRoutine.TRIGGER,
    onHandleInsufficientPermissions
  );
}

export default [fork(watchUserPermissionsActions)];
