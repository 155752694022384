import "../styles/default.scss";

import classNames from "classnames";
import React from "react";
import { compose } from "redux";

import withMarketingBarsSeen from "../../../../../hocs/withMarketingBarsSeen";
import withUserData from "../../../../../hocs/withUserData";
import PageHelper from "../../../../../lib/PageHelper";
import { PLAN_PERMISSIONS_NAMES } from "../../../../../redux/modules/auth/userPermissions/selector";
import Icon from "../components/icon";

const MARKETING_BAR_ID = "FLOATING_TEXT";

const FloatingInformation = ({
  translate,
  marketingBarsSeen,
  setMarketingBarSeen,
  unsetMarketingBarSeen,
  hasPermission
}) => {
  const hidden = marketingBarsSeen.indexOf(MARKETING_BAR_ID) !== -1;

  const isLandingPage = PageHelper.getIsLandingPage(location);

  if (
    !hasPermission([PLAN_PERMISSIONS_NAMES.WEBINAR_RECORDINGS]) ||
    isLandingPage
  ) {
    return null;
  }

  return (
    <div
      className={classNames({
        "floating-information": true,
        hidden
      })}
    >
      <div
        className="description"
        dangerouslySetInnerHTML={{
          __html: translate("floating_info_description_html")
        }}
      />
      <div
        className="icon-wrapper"
        onClick={() => {
          if (hidden) {
            unsetMarketingBarSeen(MARKETING_BAR_ID);
          } else {
            setMarketingBarSeen(MARKETING_BAR_ID);
          }
        }}
      >
        <Icon />
        <span className="fold">
          {translate("fold")} <i className="far fa-angle-up" />
        </span>
      </div>
    </div>
  );
};

FloatingInformation.propTypes = {};

export default compose(
  withMarketingBarsSeen,
  withUserData
)(FloatingInformation);
