import React from "react";

import withUserData from "../../../../../hocs/withUserData";
import ResourceResolver from "../../../../../lib/UniversalTable/ResourceResolver";
import { PLAN_PERMISSIONS_SECTIONS } from "../../../../../redux/modules/auth/userPermissions/selector";
import UniversalTableLiteWeb from "../../UniversalTableLiteWeb";

const IndexComponents = ({ canDisplaySection, stocks, translate }) => {
  if (!canDisplaySection(PLAN_PERMISSIONS_SECTIONS.INDEX_COMPONENTS)) {
    return null;
  }

  if (!stocks || !stocks.length) {
    return <div>{translate("This index has no stocks")}</div>;
  }

  return (
    <UniversalTableLiteWeb
      resourceId={ResourceResolver["stocks_list"]}
      defaultSettings={{
        filters: {
          stock_id: stocks
        }
      }}
    />
  );
};

IndexComponents.propTypes = {};

export default withUserData(IndexComponents);
