import "../styles/default.scss";

import DialogTitle from "@material-ui/core/DialogTitle";
import classNames from "classnames";
import moment from "moment-mini";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import withUserData from "../../../../../hocs/withUserData";
import accessLevelsMap from "../../../../../lib/access/accessLevelsMap";
import MediaMonitorHelper from "../../../../../lib/MediaMonitor/MediaMonitorHelper";
import { getIsMobile } from "../../../../../lib/platformHelper";
import AlwaysScrollableDialogContent from "../../AlwaysScrollableDialogContent";
import ModalBase from "../../ModalBase";

@withUserData
@withTranslations
class AggregatedMarkersModal extends ModalBase {
  getClassName(): null {
    return "squaber-interesting-stock-description-modal is-mobile-page-sheet aggregated-markers-modal";
  }

  getContent() {
    const {
      hideModal,
      aggregatedMarkers,
      translate,
      userData,
      userHasAccess,
      hasPermission,
      userAccessOverride
    } = this.props;

    const isMobile = getIsMobile();

    const userHasAccessToContent =
      userAccessOverride ||
      userHasAccess ||
      userData?.userAccessType === accessLevelsMap.HAS_PREMIUM_ACCESS;

    const content = aggregatedMarkers.map((aggregatedMarker, index) => {
      const {
        title,
        description,
        provider,
        attachments,
        is_premium,
        permissions,
        link,
        pub_datetime,
        source
      } = aggregatedMarker;

      const key = title ?? index;
      const hasAccess =
        !is_premium ||
        (is_premium && userHasAccessToContent) ||
        hasPermission(permissions);
      const premiumMessage = translate(
        "aggregated_marker_modal_content_only_with_premium"
      );

      const AggregatedMarker = ({ children }) => (
        <li className="aggregated-marker">
          <div>{`${moment(pub_datetime).format("DD.MM.YYYY, HH:mm")}${
            source ? " - " + source : ""
          }`}</div>
          {children}
        </li>
      );

      if (!hasAccess) {
        const formattedMediaMonitorEntry = new MediaMonitorHelper(
          premiumMessage,
          null,
          translate,
          null,
          title
        ).formatMediaMonitorEntry();

        return (
          <AggregatedMarker key={key}>
            <div
              dangerouslySetInnerHTML={{
                __html: formattedMediaMonitorEntry
              }}
            />
          </AggregatedMarker>
        );
      }

      if (description) {
        const formattedMediaMonitorEntry = new MediaMonitorHelper(
          description,
          provider,
          translate,
          attachments,
          title
        ).formatMediaMonitorEntry();

        return (
          <AggregatedMarker key={key}>
            <div
              dangerouslySetInnerHTML={{
                __html: formattedMediaMonitorEntry
              }}
            />
          </AggregatedMarker>
        );
      }

      const formattedMediaMonitorEntry = new MediaMonitorHelper(
        hasAccess ? "" : premiumMessage,
        null,
        translate,
        null,
        hasAccess
          ? `<a href={${link} target="_blank">${title}</a>`
          : `<p>${title}</p>`
      ).formatMediaMonitorEntry();

      return (
        <AggregatedMarker key={key}>
          <div
            dangerouslySetInnerHTML={{
              __html: formattedMediaMonitorEntry
            }}
          />
        </AggregatedMarker>
      );
    });

    const contentWrapper = (
      <div
        className={classNames({
          "text-block": true,
          "interesting-stock-content": true
        })}
      >
        {content}
      </div>
    );

    return (
      <React.Fragment>
        <DialogTitle id="interesting-stock-dialog-title">
          <span className="title">
            {translate("aggregated_marker_modal_title")}
          </span>
          <span className="close-dialog" onClick={() => hideModal()}>
            <i className="fal fa-times" />
          </span>
        </DialogTitle>
        {isMobile ? (
          <>{contentWrapper}</>
        ) : (
          <AlwaysScrollableDialogContent>
            {contentWrapper}
          </AlwaysScrollableDialogContent>
        )}
      </React.Fragment>
    );
  }
}

export default AggregatedMarkersModal;
