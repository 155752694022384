import "../styles/default.scss";

import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import withUserDataGetter from "../../../../../hocs/withUserDataGetter";
import handleAccess from "../../../../../lib/access/handleAccess";
import TagsHelper from "../../../../../lib/TagsHelper/TagsHelper";
import theme from "../../../../../theme";
import PremiumIcon from "../../PremiumIcon";

const Tag = props => {
  const {
    name,
    color,
    onClick,
    translate,
    is_premium,
    userHasAccess,
    accessLevel,
    forceAlertedTagStyle,
    permissions,
    hasPermission
  } = props;

  const userHasAccessToTag = hasPermission(permissions);

  const defaultColor = theme.colors.tagDefault;
  const finalColor = color || defaultColor;

  const isAdminTag = TagsHelper.isAdminTag(props);
  const isAlertedTag = TagsHelper.isAlertedTag(props) || forceAlertedTagStyle;
  const isPortfolioTag = TagsHelper.isPortfolioTag(props);

  let styles = {
    borderColor: finalColor,
    borderStyle: "solid",
    borderWidth: "1px"
  };

  if (isAlertedTag) {
    styles.color = theme.colors.baseNavy;
    styles.background = theme.colors.warning;
    styles.borderColor = theme.colors.warning;
  } else if (isPortfolioTag) {
    styles.color = "white";
    styles.background = theme.colors.portfolioTag;
    styles.borderColor = theme.colors.portfolioTag;
  } else if (isAdminTag) {
    styles.color = finalColor;
    styles.background = "white";
  } else {
    styles.color = "white";
    styles.background = finalColor;
  }

  const label = !isAdminTag ? name : translate(name);

  return (
    <div
      style={styles}
      className={classNames({
        tag: true,
        "no-access": !userHasAccessToTag
      })}
      onClick={event => {
        if (!userHasAccessToTag) {
          handleAccess(accessLevel);
        } else {
          onClick(event);
        }
      }}
    >
      <span>{label}</span>
      {is_premium ? <PremiumIcon userHasAccess={userHasAccess} /> : null}
    </div>
  );
};

Tag.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  tag_type: PropTypes.string
};

export default withUserDataGetter(Tag);
