import "../styles/default.scss";

import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withTranslations from "../../../../../hocs/withTranslations";
import withUserData from "../../../../../hocs/withUserData";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import { PLAN_PERMISSIONS_SECTIONS } from "../../../../../redux/modules/auth/userPermissions/selector";
import Loader from "../../Loader";
import NoPortfolios from "../../NoPortfolios";
import PortfoliosListItem from "../../PortfoliosListItem";
import SquaberButton from "../../SquaberButton";

const PortfoliosList = ({
  portfoliosListState,
  current,
  isMobile,
  translate,
  userData,
  history,
  setValue,
  canDisplaySection,
  locale
}) => {
  if (portfoliosListState.loading) {
    return <Loader small />;
  }

  if (isMobile && !userData) {
    return (
      <div className="login-notice">
        <p>{translate("Log in to see and manage your portfolios.")}</p>
        <div className="button-wrapper">
          <SquaberButton
            onClick={() => {
              history.push(
                UrlProvider.getUrl("fe.login", {
                  locale
                })
              );
              setValue("");
            }}
          >
            {translate("Log in")}
          </SquaberButton>
        </div>
      </div>
    );
  }

  if (
    isMobile &&
    !portfoliosListState.loading &&
    portfoliosListState.data &&
    portfoliosListState.data.portfolios.length === 0
  ) {
    return <NoPortfolios />;
  }

  return (
    <div className="portfolios-list">
      {!portfoliosListState.loading &&
      portfoliosListState.data &&
      portfoliosListState.data.portfolios.length ? (
        <React.Fragment>
          {portfoliosListState.data.portfolios.map(portfolio => (
            <PortfoliosListItem
              key={portfolio.id}
              data={portfolio}
              active={Number(portfolio.id) === Number(current)}
            />
          ))}
          <SquaberButton
            onClick={() => {
              const { userIsNotLoggedIn } = userData;
              const userHasAccess = canDisplaySection(
                PLAN_PERMISSIONS_SECTIONS.PORTFOLIOS
              );
              const portfoliosCount = portfoliosListState.data
                ? portfoliosListState.data.portfolios.length
                : 0;

              if (userIsNotLoggedIn) {
                showModal("RegisterModal");
              } else if (portfoliosCount && !userHasAccess) {
                showModal("PremiumModal", { name: "create portfolio attempt" });
              } else {
                showModal("PortfolioDetailsModal", {
                  title: translate("Add portfolio"),
                  portfolioData: {
                    name: `Portfolio ${portfoliosCount + 1}`,
                    description: ""
                  },
                  action: "add"
                });
              }
            }}
          >
            {translate("Add portfolio")}
          </SquaberButton>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default compose(
  withBottomNavigation("navState"),
  withRouter,
  withUserData,
  withDeviceType,
  withTranslations
)(PortfoliosList);
