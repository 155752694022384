import "../styles/default.scss";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Row } from "reactstrap";

import { UrlProvider } from "../../../../../api/UrlProvider";
import config from "../../../../../config";
import withFollowedStocksMap from "../../../../../hocs/withFollowedStocksMap";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import withMarketTranlstaions from "../../../../../hocs/withMarketTranslations";
import withMediaMonitorEntries from "../../../../../hocs/withMediaMonitorEntries";
import withMobileMediaQuery from "../../../../../hocs/withMobileMediaQuery";
import withStockAnalysesCategories from "../../../../../hocs/withStockAnalysesCategories";
import withStockBasicInfo from "../../../../../hocs/withStockBasicInfo";
import withStockData from "../../../../../hocs/withStockData";
import withStockPageTVChart from "../../../../../hocs/withStockPageTVChart";
import withStockSeoMetadata from "../../../../../hocs/withStockSeoMetadata";
import withStockTags from "../../../../../hocs/withStockTags";
import withUserData from "../../../../../hocs/withUserData";
import accessLevelsMap from "../../../../../lib/access/accessLevelsMap";
import { getSectionSlugBySectionId } from "../../../../../lib/getSectionSlugBySectionId";
import { getTranslatedMarket } from "../../../../../lib/getTranslatedMarket";
import stockPageOptionalSections from "../../../../../lib/stockPageOptionalSections";
import { PLAN_PERMISSIONS_SECTIONS } from "../../../../../redux/modules/auth/userPermissions/selector";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import Loader from "../../../common/Loader";
import PageTitle from "../../../common/PageTitle";
import PremiumIcon from "../../../common/PremiumIcon";
import { SEOHelmet } from "../../../common/SEOHelmet/SEOHelmet";
import StockPageSection from "../../../common/StockPageSection";
import StockPagePreloadedHeader from "../components/StockPageHeaderWrapper/StockPagePreloadedHeader";

// prettier-ignore
const FollowedStocksList = lazy(() => import("../../../common/FollowedStocksList"));
const StockPageHeader = lazy(() => import("../../../common/StockPageHeader"));
const AdminComment = lazy(() => import("../../../common/AdminComment"));
const UserNotes = lazy(() => import("../../../common/UserNotes"));
const SignalDetails = lazy(() => import("../../../common/SignalDetails"));
const PriceAlerts = lazy(() => import("../../../common/PriceAlerts"));
const StockPageTVChart = lazy(() => import("../components/StockPageTVChart"));
const StockBasicInfo = lazy(() => import("../../../common/StockBasicInfo"));
const StockDividends = lazy(() => import("../../../common/StockDividends"));
// prettier-ignore
const StockInsiderTransactions = lazy(() => import("../../../common/StockInsiderTransactions"));
// prettier-ignore
const StockRecommendations = lazy(() => import("../../../common/StockRecommendations"));
const MediaMonitor = lazy(() => import("../../../common/MediaMonitor"));
const StockHolders = lazy(() => import("../../../common/StockHolders"));
const RateOfReturns = lazy(() => import("../../../common/RateOfReturns"));
// prettier-ignore
const FinancialAnalysis = lazy(() => import("../../../common/FinancialAnalysis"));
// prettier-ignore
const FinancialIndicators = lazy(() => import("../../../common/FinancialIndicators"));
const HistoryWithStock = lazy(() => import("../../../common/HistoryWithStock"));
const StockAnalyses = lazy(() => import("../../../common/StockAnalyses"));
// prettier-ignore
const TransactionSignalSections = lazy(() => import("../../../common/TransactionSignalSections"));
const AlertManagement = lazy(() => import("../../../common/AlertManagement"));
const IndexComponents = lazy(() => import("../../../common/IndexComponents"));

@withStockData(undefined, undefined, true)
@withMobileMediaQuery
@withGlobalConfigFields([globalConfigFields.OFFER_URL])
@withStockBasicInfo
@withFollowedStocksMap
@withUserData
@withStockAnalysesCategories
@withStockTags
@withMediaMonitorEntries(true)
@withMarketTranlstaions
@withStockSeoMetadata
@withStockPageTVChart
class StockPage extends React.Component {
  state = {
    analysesGroupedByCategories: {},
    analysesWereGrouped: false
  };

  async componentDidMount() {
    document.body.classList.add("is-stock-page");
  }

  componentWillUnmount() {
    document.body.classList.remove("is-stock-page");
  }

  render() {
    const {
      stockData,
      stockDataLoading,
      stockDataErrorResponse,
      translate,
      isMobileMediaQuery,
      match: {
        params: { stockId: ticker, marketId }
      },
      stockTags,
      userHasAccess,
      canDisplaySection,
      userAccessLevel,
      globalConfig: { offerUrl },
      mediaMonitor,
      stockBasicInfo,
      locale,
      location,
      stock,
      stockLoading,
      stockError,
      followedStocksMap,
      skipFetchStockData,
      setSkipFetchStockData,
      setSkipFetchMonitorEntries,
      marketTranslations,
      analysisCategories,
      seoData,
      preparedSlugList
    } = this.props;

    const translatedMarketId = getTranslatedMarket(
      locale,
      marketId,
      marketTranslations
    );

    const notFound =
      (!stockLoading && stockError?.response?.status === 404) ||
      (stockDataErrorResponse && stockDataErrorResponse.status === 404);
    const shouldShowPremiumNotice = !userHasAccess;
    const hasStockBasicInfo =
      stockBasicInfo && !isEmpty(stockBasicInfo) && config.vendor === "squaber";

    const marketsWithAdditionalSections = ["GPW", "NC"];

    const redirectTo = location.pathname;
    const userStock = followedStocksMap?.[stock?.stock?.id];

    return (
      <div>
        <Row className="stock-page-outer-wrapper">
          {!isMobileMediaQuery ? (
            <div className="stocks-list-wrapper">
              <Suspense fallback={<Loader small />}>
                <FollowedStocksList />
              </Suspense>
            </div>
          ) : null}
          <div className="stock-page-wrapper">
            <div className="stock-page">
              {stockLoading || stock ? (
                <PageTitle
                  title={
                    stockLoading
                      ? translate("Loading %{ticker}", { ticker })
                      : seoData?.meta_title
                      ? seoData.meta_title
                      : stock.stock.name
                  }
                />
              ) : null}
              {stockLoading || stock ? (
                seoData ? (
                  <SEOHelmet data={seoData} />
                ) : null
              ) : null}
              <div className={"stock-page-top-wrapper"}>
                <Suspense fallback={<Loader small />}>
                  {!stockLoading && stock ? (
                    <AdminComment
                      stock={stock}
                      attention_updated={stock.stock.attention_updated}
                      market={stock.stock.market}
                      ticker={stock.stock.ticker}
                    />
                  ) : null}
                  {stockDataLoading || skipFetchStockData ? (
                    <StockPagePreloadedHeader
                      key={"StockPagePreloadedHeader"}
                    />
                  ) : !stockDataLoading &&
                    stockData &&
                    !stockLoading &&
                    stock ? (
                    <StockPageHeader
                      stockData={stock}
                      stock={stock.stock}
                      user_stock={userStock}
                      quote={stock.quote}
                      signal={stockData.signal}
                      canDisplaySection={canDisplaySection}
                      title={seoData ? seoData.h1_title : null}
                      tags={stockTags.data}
                      stockId={stock.stock.id}
                    />
                  ) : null}

                  <StockPageTVChart
                    stockId={ticker}
                    marketId={marketId}
                    onRefreshEnded={() => {
                      setSkipFetchStockData(false);
                      setSkipFetchMonitorEntries(false);
                    }}
                  />
                </Suspense>
              </div>

              {stockDataLoading || stockLoading ? (
                <Loader />
              ) : stockData && stock ? (
                <React.Fragment>
                  {shouldShowPremiumNotice ? (
                    <div className="premium-notice">
                      <div className="premium-notice-icon">
                        <PremiumIcon />
                      </div>
                      <a
                        className="premium-notice-description"
                        href={
                          userAccessLevel === accessLevelsMap.NO_PREMIUM_ACCESS
                            ? offerUrl || config.defaultPremiumOfferUrl
                            : redirectTo
                            ? UrlProvider.getUrl("fe.registerWithRedirect", {
                                locale,
                                redirectUrl: redirectTo
                              })
                            : UrlProvider.getUrl("fe.register", { locale })
                        }
                      >
                        <span>
                          {userAccessLevel === accessLevelsMap.NO_PREMIUM_ACCESS
                            ? translate("premium_section_text")
                            : translate("not_logged_section_text")}
                        </span>{" "}
                        <span className="underline">
                          {translate("Check out Squaber premium offer.")}
                        </span>
                      </a>
                    </div>
                  ) : null}
                  <StockPageSection
                    title={translate("Your price alerts")}
                    slug={getSectionSlugBySectionId(
                      preparedSlugList,
                      "section-price-alerts"
                    )}
                    sectionId={"section-price-alerts"}
                    isPremium
                    sectionPermissions={PLAN_PERMISSIONS_SECTIONS.PRICE_ALERTS}
                    ticker={stock.stock.ticker}
                    market={translatedMarketId}
                  >
                    <Suspense fallback={<Loader small />}>
                      <PriceAlerts
                        stockId={stock.stock.id}
                        currency={stock.stock.currency}
                      />
                    </Suspense>
                  </StockPageSection>
                  {stockData.signal ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Current signal")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-current-signal"
                      )}
                      sectionId={"section-current-signal"}
                      isPremium
                      sectionPermissions={PLAN_PERMISSIONS_SECTIONS.SIGNALS}
                      label={
                        canDisplaySection(PLAN_PERMISSIONS_SECTIONS.SIGNALS)
                          ? {
                              type:
                                stockData.signal.type === "buy"
                                  ? "success"
                                  : "error",
                              text:
                                stockData.signal.type === "buy"
                                  ? translate("Bargain")
                                  : translate("Warning")
                            }
                          : null
                      }
                    >
                      <SignalDetails
                        signal={stockData.signal}
                        currency={stock.stock.currency}
                        userStock={userStock}
                      />
                    </StockPageSection>
                  ) : null}
                  {analysisCategories.length > 0
                    ? analysisCategories.map(category => (
                        <StockPageSection
                          ticker={stock.stock.ticker}
                          market={translatedMarketId}
                          title={category.name}
                          slug={getSectionSlugBySectionId(
                            preparedSlugList,
                            category.notifications_unique_id
                          )}
                          sectionId={category.notifications_unique_id}
                          key={`section-stock-analyses-${category.id}`}
                          sectionPermissions={
                            PLAN_PERMISSIONS_SECTIONS.ANALYSIS_CATEGORIES
                          }
                        >
                          <Suspense fallback={<Loader small />}>
                            <StockAnalyses
                              data={category}
                              ticker={stock.stock.ticker}
                              market={stock.stock.market}
                            />
                          </Suspense>
                        </StockPageSection>
                      ))
                    : null}
                  {stockData?.situation?.description_html ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("General technical situation")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-general-technical-situation"
                      )}
                      sectionId={"section-general-technical-situation"}
                    >
                      <Suspense fallback={<Loader small />}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: stockData.situation.description_html
                          }}
                        />
                      </Suspense>
                    </StockPageSection>
                  ) : null}
                  {stockData &&
                  stockData.transaction_strategies &&
                  stockData.transaction_strategies.length > 0 ? (
                    <Suspense fallback={<Loader small />}>
                      <TransactionSignalSections
                        ticker={stock.stock.ticker}
                        market={translatedMarketId}
                        preparedSlugList={preparedSlugList}
                        strategies={stockData.transaction_strategies}
                        signals={stockData.transaction_signals}
                        currency={stock.stock.currency}
                        stockId={stock.stock.id}
                        userStock={userStock}
                      />
                    </Suspense>
                  ) : null}
                  {stockData &&
                  stockData.visible_optional_sections &&
                  stockData.visible_optional_sections.includes(
                    stockPageOptionalSections.FINANCIAL_SITUATION
                  ) ? (
                    <>
                      <StockPageSection
                        ticker={stock.stock.ticker}
                        market={translatedMarketId}
                        title={translate("Financial analysis summary")}
                        slug={getSectionSlugBySectionId(
                          preparedSlugList,
                          "section-financial-analysis-summary"
                        )}
                        sectionId={"section-financial-analysis-summary"}
                      >
                        <Suspense fallback={<Loader small />}>
                          <FinancialAnalysis
                            userStock={userStock}
                            stockId={stock.stock.id}
                            key={stock.stock.id}
                          />
                        </Suspense>
                      </StockPageSection>
                      <StockPageSection
                        ticker={stock.stock.ticker}
                        market={translatedMarketId}
                        title={translate("Financial indicators")}
                        slug={getSectionSlugBySectionId(
                          preparedSlugList,
                          "section-financial-indicators"
                        )}
                        sectionId={"section-financial-indicators"}
                        isPremium
                        sectionPermissions={
                          PLAN_PERMISSIONS_SECTIONS.FINANCIAL_INDICATORS
                        }
                      >
                        <Suspense fallback={<Loader small />}>
                          <FinancialIndicators
                            userStock={userStock}
                            stockId={stock.stock.id}
                            key={stock.stock.id}
                          />
                        </Suspense>
                      </StockPageSection>
                    </>
                  ) : null}
                  {this?.props?.authToken && stock?.stock?.id ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Your notes about this stock")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-user-notes"
                      )}
                      sectionId={"section-user-notes"}
                      isPremium
                      sectionPermissions={PLAN_PERMISSIONS_SECTIONS.USER_NOTES}
                    >
                      <Suspense fallback={<Loader small />}>
                        <UserNotes
                          authToken={this.props.authToken}
                          stockId={stock.stock.id}
                        />
                      </Suspense>
                    </StockPageSection>
                  ) : null}
                  {stock.stock.description_activities ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Description")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-description"
                      )}
                      sectionId={"section-description"}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: stock.stock.description_activities
                        }}
                      />
                    </StockPageSection>
                  ) : null}
                  {hasStockBasicInfo ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Basic info")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-basic-info"
                      )}
                      sectionId={"section-basic-info"}
                    >
                      <Suspense fallback={<Loader small />}>
                        <StockBasicInfo
                          info={stockBasicInfo}
                          key={stock.stock.id}
                        />
                      </Suspense>
                    </StockPageSection>
                  ) : null}
                  {mediaMonitor &&
                  mediaMonitor.messages &&
                  mediaMonitor.messages.length > 0 ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Media monitor")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-media-monitor"
                      )}
                      sectionId={"section-media-monitor"}
                    >
                      <Suspense fallback={<Loader small />}>
                        <MediaMonitor
                          ticker={stock.stock.ticker}
                          market={translatedMarketId}
                          data={mediaMonitor}
                          userStock={userStock}
                        />
                      </Suspense>
                    </StockPageSection>
                  ) : null}
                  {!stock.stock.is_index &&
                  marketsWithAdditionalSections.indexOf(stock.stock.market) !==
                    -1 ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Stock holders")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-stockholders"
                      )}
                      sectionId={"section-stockholders"}
                    >
                      <Suspense fallback={<Loader small />}>
                        <StockHolders
                          stockholders={stockData.stockholders}
                          shareAmount={stock.stock.basic_info.share_amount}
                        />
                      </Suspense>
                    </StockPageSection>
                  ) : null}
                  {stockData &&
                  stockData.visible_optional_sections &&
                  stockData.visible_optional_sections.includes(
                    stockPageOptionalSections.RECOMMENDATIONS
                  ) ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Recommendations")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-recommendations"
                      )}
                      sectionId={"section-recommendations"}
                    >
                      <Suspense fallback={<Loader small />}>
                        <StockRecommendations
                          stockId={stock.stock.id}
                          currency={stock.stock.currency}
                        />
                      </Suspense>
                    </StockPageSection>
                  ) : null}
                  {stockData?.visible_optional_sections?.indexOf(
                    stockPageOptionalSections.DIVIDENDS
                  ) !== -1 ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Dividends")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-dividends"
                      )}
                      sectionId={"section-dividends"}
                    >
                      <Suspense fallback={<Loader small />}>
                        <StockDividends
                          currency={stock.stock.currency}
                          stockId={stock.stock.id}
                        />
                      </Suspense>
                    </StockPageSection>
                  ) : null}
                  {stock.stock.is_index ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Index components")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-index-components"
                      )}
                      sectionId={"section-index-components"}
                      sectionPermissions={
                        PLAN_PERMISSIONS_SECTIONS.INDEX_COMPONENTS
                      }
                      isPremium
                    >
                      <Suspense fallback={<Loader small />}>
                        <IndexComponents stocks={stock.stock.indexes} />
                      </Suspense>
                    </StockPageSection>
                  ) : null}
                  {stockData?.visible_optional_sections &&
                  stockData.visible_optional_sections.includes(
                    stockPageOptionalSections.INSIDERS_TRANSACTIONS
                  ) ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Insiders transactions")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-insiders-transactions"
                      )}
                      sectionId={"section-insiders-transactions"}
                    >
                      <Suspense fallback={<Loader small />}>
                        <StockInsiderTransactions
                          market={translatedMarketId}
                          ticker={stock.stock.ticker}
                        />
                      </Suspense>
                    </StockPageSection>
                  ) : null}
                  {stockData.rate_of_returns &&
                  stockData.rate_of_returns.length > 0 &&
                  config.vendor === "squaber" ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Rate of returns")}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-rate-of-returns"
                      )}
                      sectionId={"section-rate-of-returns"}
                    >
                      <Suspense fallback={<Loader small />}>
                        <RateOfReturns data={stockData.rate_of_returns} />
                      </Suspense>
                    </StockPageSection>
                  ) : null}
                  {!stock.stock.is_index &&
                  marketsWithAdditionalSections.indexOf(stock.stock.market) !==
                    -1 ? (
                    <StockPageSection
                      ticker={stock.stock.ticker}
                      market={translatedMarketId}
                      title={translate("Your history with %{ticker}", {
                        ticker: stock.stock.ticker
                      })}
                      slug={getSectionSlugBySectionId(
                        preparedSlugList,
                        "section-history-with-stock"
                      )}
                      sectionId={"section-history-with-stock"}
                      isPremium
                      sectionPermissions={
                        PLAN_PERMISSIONS_SECTIONS.SECTION_HISTORY_WITH_STOCK
                      }
                    >
                      <Suspense fallback={<Loader small />}>
                        <HistoryWithStock
                          currency={stock.stock.currency}
                          stock={stock.stock}
                          stockId={stock.stock.id}
                          quote={stock.quote}
                        />
                      </Suspense>
                    </StockPageSection>
                  ) : null}
                  <StockPageSection
                    ticker={stock.stock.ticker}
                    market={translatedMarketId}
                    title={translate("Alert management")}
                    slug={getSectionSlugBySectionId(
                      preparedSlugList,
                      "section-history-alert-management"
                    )}
                    sectionId={"section-history-alert-management"}
                    isPremium
                    sectionPermissions={
                      PLAN_PERMISSIONS_SECTIONS.SECTION_HISTORY_ALERT_MANAGER
                    }
                  >
                    <Suspense fallback={<Loader small />}>
                      <AlertManagement
                        stockId={stock.stock.id}
                        userStock={userStock}
                        ticker={stock.stock.ticker}
                        market={translatedMarketId}
                      />
                    </Suspense>
                  </StockPageSection>
                </React.Fragment>
              ) : null}
              {notFound ? (
                <div className="stock-not-found-notice">
                  <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                    <meta name="googlebot" content="noindex, nofollow" />
                    <meta name="bingbot" content="noindex, nofollow" />
                  </Helmet>
                  <h1>{translate("Stock page does not exist")}</h1>
                  <p>
                    {translate(
                      "Perhaps the company has changed its name or ticker. Make sure by entering the company name in the search engine - if the company you are looking for is not found, i.e. it is not listed on the markets we analyze under the name. Contact us via chat for more information"
                    )}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

StockPage.propTypes = {
  stockData: PropTypes.object
};

export default StockPage;
