import "../styles/default.scss";

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { compose } from "redux";
import SweetScroll from "sweet-scroll";

import { UrlProvider } from "../../../../../api/UrlProvider";
import config from "../../../../../config";
import withCollapsibleSection from "../../../../../hocs/withCollapsibleSection";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import withStockBasicInfo from "../../../../../hocs/withStockBasicInfo";
import withUserData from "../../../../../hocs/withUserData";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import Label from "../../Label";
import PremiumIcon from "../../PremiumIcon";
import SquaberLink from "../../SquaberLink/modules/default";

const StockPageSection = ({
  title,
  children,
  label,
  sectionId,
  isPremium,
  sectionPermissions = [],
  canDisplaySection,
  ticker,
  locale,
  market,
  slug,
  stock,
  match: {
    params: { section: currentSection }
  },
  translate,
  translationExists,
  stockBasicInfo
}) => {
  const shouldShowPremiumNotice = !canDisplaySection(sectionPermissions);

  const sectionRef = useRef(null);

  if (shouldShowPremiumNotice) {
    return null;
  }

  const urlPattern = "fe.stockPageSection";

  const urlProviderVariables = {
    locale,
    marketId: market,
    stockId: ticker.toLowerCase(),
    section: slug
  };

  const sectionUrl = UrlProvider.getUrl(urlPattern, urlProviderVariables);

  const canonicalUrl =
    slug !== sectionId
      ? UrlProvider.getUrl(urlPattern, urlProviderVariables, config.webAppUrl)
      : null;

  const sectionOpen = useMemo(() => {
    return [slug, sectionId].includes(currentSection);
  }, [currentSection, slug, sectionId]);

  useEffect(() => {
    const sweetScroll = new SweetScroll({
      duration: 500,
      vertical: true,
      updateURL: false
    });

    if (sectionOpen && sectionRef.current) {
      sweetScroll.toElement(sectionRef.current, {
        offset: -100
      });
    }

    return () => sweetScroll.destroy();
  }, [currentSection, sectionOpen, sectionRef]);

  const fullName = stockBasicInfo.fullname
    ? stockBasicInfo.fullname
    : stock.stock.name;

  const sectionDescriptionTranslationKey = `${sectionId}__description`;

  return (
    <>
      {sectionOpen && !!canonicalUrl ? (
        <Helmet>
          <link rel="canonical" key="canonical" href={canonicalUrl} />
        </Helmet>
      ) : null}
      <div
        id={sectionId}
        className={classNames({
          "squaber-stock-page-section": true
        })}
        ref={sectionRef}
      >
        <div className="Collapsible">
          <SquaberLink
            to={sectionUrl}
            className={classNames({
              Collapsible__trigger: true,
              "is-open": sectionOpen
            })}
          >
            <div className="squaber-stock-page-section-header">
              {isPremium ? (
                <PremiumIcon userHasAccess={!shouldShowPremiumNotice} />
              ) : null}
              <p className="description">
                {title} <span>{` - ${fullName}`}</span>
              </p>
              {label ? <Label type={label.type}>{label.text}</Label> : null}
            </div>
          </SquaberLink>
          <div
            className={classNames({
              Collapsible__contentOuter: true,
              "is-open": sectionOpen
            })}
          >
            {sectionOpen ? (
              <React.Fragment>
                {translationExists(sectionDescriptionTranslationKey) ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: translate(sectionDescriptionTranslationKey, {
                        stock__ticker: ticker,
                        stock__market: market,
                        stock__short_name: stock?.stock?.short_name
                      })
                    }}
                  />
                ) : null}
                {children}
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

StockPageSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  sectionId: PropTypes.string,
  label: PropTypes.shape({
    type: PropTypes.oneOf(["success", "error", "info"]),
    text: PropTypes.string
  }),
  alwaysOpen: PropTypes.bool
};

export default compose(
  withUserData,
  withStockBasicInfo,
  withCollapsibleSection,
  withRouter,
  withGlobalConfigFields([globalConfigFields.OFFER_URL])
)(StockPageSection);
