import "../styles/default.scss";

import autoBind from "auto-bind";
import classNames from "classnames";
import { cloneDeep, debounce, isArray, isEmpty, isEqual } from "lodash";
import moment from "moment-mini";
import PropTypes from "prop-types";
import qs from "qs";
import React from "react";
import { withRouter } from "react-router";

import { UrlProvider } from "../../../../../api/UrlProvider";
import Price from "../../../../../common/Price";
import config from "../../../../../config";
import withActionInProgress from "../../../../../hocs/withActionInProgress";
import withAdvancedChart from "../../../../../hocs/withAdvancedChart";
import withChartData from "../../../../../hocs/withChartData";
import withChartTemplates from "../../../../../hocs/withChartTemplates";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withMarketTransltaions from "../../../../../hocs/withMarketTranslations";
import withQuoteData from "../../../../../hocs/withQuoteData";
import withSimpleChartData from "../../../../../hocs/withSimpleChartData";
import withUserData from "../../../../../hocs/withUserData";
import accessLevelsMap from "../../../../../lib/access/accessLevelsMap";
import getAccessLevel from "../../../../../lib/access/getAccessLevel";
import handleAccess from "../../../../../lib/access/handleAccess";
import { widget as Widget } from "../../../../../lib/charting_library/charting_library";
import getClientTimeZone from "../../../../../lib/getClientTimeZone";
import { getTranslatedMarket } from "../../../../../lib/getTranslatedMarket";
import MediaMonitorHelper from "../../../../../lib/MediaMonitor/MediaMonitorHelper";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import alignMovingAverageOptions from "../../../../../lib/TradingView/alignMovingAverageOptions";
import defaultStudyChartData from "../../../../../lib/TradingView/defaultStudyChartData";
import getSquaberTrendyIndicator from "../../../../../lib/TradingView/indicators/getSquaberTrendyIndicator";
import { markerTypesNames } from "../../../../../lib/TradingView/markerTypes";
import mergeDefaultTemplateWithCurrentChart, {
  EMA_STUDY_NAME,
  MA_STUDY_NAME
} from "../../../../../lib/TradingView/mergeDefaultTemplateWithCurrentChart";
import SquaberIntervalMap from "../../../../../lib/TradingView/SquaberIntervalMap";
import SquaberIntervalTypes from "../../../../../lib/TradingView/SquaberIntervalTypes";
import SquaberTVDrawing from "../../../../../lib/TradingView/SquaberTVDrawing";
import SquaberTVMarkersController from "../../../../../lib/TradingView/SquaberTVMarkersController";
import SquaberTVQuotationNotifier from "../../../../../lib/TradingView/SquaberTVQuotationNotifier";
import SquaberTVShapeManager from "../../../../../lib/TradingView/SquaberTVShapeManager";
import TVDataFeed from "../../../../../lib/TradingView/TVDataFeed";
import DEFAULT_DRAWN_OBJECTS_OPTIONS from "../../../../../lib/TvChart/defaultDrawnObjectsOptions";
import getInterval, {
  getIntervalFromIntervalTypeId
} from "../../../../../lib/TvChart/getMainSeriesInterval";
import {
  PLAN_PERMISSIONS_NAMES,
  PLAN_PERMISSIONS_SECTIONS
} from "../../../../../redux/modules/auth/userPermissions/selector";
import theme from "../../../../../theme";
import CustomSimpleChart from "../../CustomSimpleChart/modules/default";
import TVChartAnalysisOptions from "../../TVChartAnalysisOptions";
import TVChartBackupList from "../../TVChartBackupList/modules/default";
import TVChartDrawnObjectsOptions from "../../TVChartDrawnObjectsOptions";
import TVChartStocksList from "../../TVChartStocksList/modules/default";
import TVChartTemplatesOptions from "../../TVChartTemplatesOptions";

@withChartTemplates
@withActionInProgress
@withDeviceType
@withUserData
@withChartData
@withRouter
@withAdvancedChart
@withSimpleChartData(props => props.stock.id, true)
@withQuoteData()
@withMarketTransltaions
class TVChart extends React.PureComponent {
  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  static propTypes = {
    stock: PropTypes.object.isRequired
  };

  static defaultProps = {
    symbol: "AAPL",
    interval: "D",
    containerId: "tv_chart_container",
    datafeedUrl: "https://demo_feed.tradingview.com",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id"
  };

  tvWidget = null;
  tvWidgetReady = false;
  tvWidgetHeaderReady = false;
  drawnPriceAlerts = [];
  markersInitiallyDrawn = false;
  chartTemplateUploaded = false;
  timeouts = {};

  defaultOptionsVisibility = {
    drawnObjectsOptionsVisible: false,
    templatesOptionsVisible: false,
    analyticsOptionsVisible: false,
    stocksListVisible: false,
    backupsListVisible: false
  };

  state = {
    shouldShowFullScreen: false,
    isLandscape: false,
    userData: null,
    ...this.defaultOptionsVisibility,
    backupsList: [],
    drawnObjectsOptions: DEFAULT_DRAWN_OBJECTS_OPTIONS,
    interval: null,
    chartRefreshing: false
  };

  shapeData = {
    preventDraw: false,
    actualIntervalTypeId: SquaberIntervalTypes.daily,
    shapes: {}
  };

  debouncedSaveChart = debounce(this.saveChart, 3000);

  actionInProgressText =
    "Chart data is now being updated. If you leave page now, your changes might not be saved";

  saveChartDebouncer() {
    this.debouncedSaveChart.cancel();

    this.debouncedSaveChart();
  }

  showActionInProgress() {
    const { showActionInProgress, translate } = this.props;

    showActionInProgress(translate(this.actionInProgressText));
  }

  hideActionInProgress() {
    const { hideActionInProgress } = this.props;

    hideActionInProgress();
  }

  getUserData() {
    return this.state.userData || this.props.userData;
  }

  getDaysSinceStartOfTheYear() {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    const diff =
      now -
      start +
      (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
    const oneDay = 1000 * 60 * 60 * 24;

    return Math.floor(diff / oneDay);
  }

  handleRefreshStarted() {
    this.setState({ chartRefreshing: true });
    this.props.onRefreshStarted?.();
  }

  handleRefreshEnded = debounce(() => {
    this.props.onRefreshEnded?.();
  }, 500);

  componentDidMount() {
    this.initChart();
  }

  initChart() {
    let {
      tvChartRoutinePromiseCreator,
      tvChartGetUserDataRoutinePromiseCreator,
      stock,
      latestQuoteDatetimeUtc,
      userData,
      chartTemplatesState,
      reportDates,
      dividends,
      insiderTransactions,
      translate,
      mediaMonitorMessages,
      clearMediaMonitorEntries,
      clearLastMinMaxPoints,
      clearInsiderTransactions,
      lastMinMaxPoints,
      setAdvancedChartVisible,
      refreshPriceAlerts,
      refreshDividends,
      refreshReportDates,
      clearReportDates,
      clearDividends,
      clearPriceAlerts
    } = this.props;

    clearMediaMonitorEntries();
    clearLastMinMaxPoints();
    clearReportDates();
    clearDividends();
    clearPriceAlerts();
    clearInsiderTransactions();

    const { drawnObjectsOptions } = this.state;

    const urlParams = window.location.search
      ? qs.parse(window.location.search.replace("?", ""))
      : {};

    let token = urlParams.auth_token;
    let userAccessType = urlParams.userAccessType;
    let shouldShowFullScreen = urlParams.tv_full_screen === "true";

    if (token) {
      userData = {
        token,
        userAccessType
      };
    }

    this.setState({
      token,
      shouldShowFullScreen,
      userData
    });

    if (shouldShowFullScreen) {
      setAdvancedChartVisible(true);
    }

    let intervals = config.tradingViewOptions.defaultMarketsIntervals;

    const specificMarketIntervals = config.tradingViewOptions.marketsIntervals.find(
      marketInterval => {
        return (
          marketInterval.markets.findIndex(
            market => market === stock.market.toLowerCase()
          ) !== -1
        );
      }
    );

    if (specificMarketIntervals) {
      intervals = specificMarketIntervals.intervals;
    }

    const shapeManager = new SquaberTVShapeManager(
      this.shapeData.shapes,
      stock
    );

    this.dataFeed = new TVDataFeed(
      stock,
      tvChartRoutinePromiseCreator,
      new SquaberTVQuotationNotifier(stock.id, false),
      shapeManager,
      translate,
      intervals,
      latestQuoteDatetimeUtc
    );

    const widgetOptions = {
      symbol: stock.ticker,
      allow_symbol_change: false,
      timezone: getClientTimeZone(),
      datafeed: this.dataFeed,
      loading_screen: { backgroundColor: "#FFFFFF" },
      container: this.props.containerId,
      library_path: this.props.libraryPath,
      toolbar_bg: "#FFFFFF",
      auto_save_delay: 30,
      time_frames: [
        {
          text: "1m",
          resolution: "1D",
          title: "1M"
        },
        {
          text: "6m",
          resolution: "1D",
          title: "6M"
        },
        {
          text: "1y",
          resolution: "1D",
          title: "1Y"
        },
        {
          text: "2y",
          resolution: "1D",
          title: "2Y"
        },
        {
          text: "3y",
          resolution: "1D",
          title: "3Y"
        },
        {
          text: "5y",
          resolution: "1W",
          title: "5Y"
        },
        {
          text: `${this.getDaysSinceStartOfTheYear()}d`,
          resolution: "1D",
          title: "YTD"
        },
        {
          text: "500m",
          resolution: "1W",
          title: "All"
        }
      ],
      overrides: {
        "paneProperties.background": "#FFFFFF",

        // Candles
        "mainSeriesProperties.candleStyle.upColor": "#6CA584",
        "mainSeriesProperties.candleStyle.downColor": "#D65748",
        "mainSeriesProperties.candleStyle.drawWick": true,
        "mainSeriesProperties.candleStyle.drawBorder": true,
        "mainSeriesProperties.candleStyle.borderColor": "#378658",
        "mainSeriesProperties.candleStyle.borderUpColor": "#235438",
        "mainSeriesProperties.candleStyle.borderDownColor": "#5A1C16",
        "mainSeriesProperties.candleStyle.wickUpColor": "#737375",
        "mainSeriesProperties.candleStyle.wickDownColor": "#737375",
        "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,

        // Heikin-Ashi
        "mainSeriesProperties.haStyle.upColor": "#6CA584",
        "mainSeriesProperties.haStyle.downColor": "#D65748",
        "mainSeriesProperties.haStyle.drawWick": true,
        "mainSeriesProperties.haStyle.drawBorder": true,
        "mainSeriesProperties.haStyle.borderColor": "#378658",
        "mainSeriesProperties.haStyle.borderUpColor": "#235438",
        "mainSeriesProperties.haStyle.borderDownColor": "#5A1C16",
        "mainSeriesProperties.haStyle.wickColor": "#737375",
        "mainSeriesProperties.haStyle.barColorsOnPrevClose": false
      },
      locale: this.props.locale,
      disabled_features: [
        "header_symbol_search",
        "header_interval_dialog_button",
        "header_compare",
        "items_favoriting",
        "display_market_status",
        "link_to_tradingview",
        "header_fullscreen_button"
      ],
      enabled_features: [
        "side_toolbar_in_fullscreen_mode",
        "keep_left_toolbar_visible_on_small_screens",
        "dont_show_boolean_study_arguments",
        "hide_last_na_study_output",
        "move_logo_to_main_pane",
        "charting_library_single_symbol_request"
      ],
      autosize: true,
      custom_indicators_getter: function(PineJS) {
        return Promise.resolve([getSquaberTrendyIndicator(PineJS)]);
      },
      custom_translate_function: (key, options) => {
        const prefix = "charting_library";

        try {
          const finalKey = `${prefix}.${key}`;
          const translation = translate(finalKey, options);

          if (finalKey === translation) {
            return null;
          }

          return translation;
        } catch (e) {
          console.warn("Translation error in Charting library ");
        }

        return null;
      }
    };

    if (window.ReactNativeWebView) {
      widgetOptions.disabled_features.push("header_screenshot");
    }

    if (config.tradingViewOptions && config.tradingViewOptions.logo) {
      widgetOptions.logo = {
        image: require("../../../../../assets/images/squaber-logo-tv.png")
      };
    }

    const accessLevel =
      (userData ? userData.userAccessType : null) ||
      getAccessLevel(token || userData);

    tvChartGetUserDataRoutinePromiseCreator(stock.id).then(
      chartDataResponse => {
        let chartData = cloneDeep(chartDataResponse);

        if (chartData.type && typeof chartData.payload !== "undefined") {
          chartData = chartData.payload;
        }

        if (typeof chartData.settings === "string") {
          chartData.settings = JSON.parse(chartData.settings);
        }

        if (
          typeof chartData?.backups !== "undefined" &&
          Array.isArray(chartData.backups) &&
          chartData.backups.length > 0
        ) {
          this.setState({
            backupsList: chartData.backups
          });
        }

        if (chartData.settings) {
          this.setState({
            drawnObjectsOptions: this.addLabelsToDrawnObjectsOptions(
              chartData.settings
            )
          });
        }

        let defaultChartTemplate = null;

        if (chartData && chartData.chart) {
          chartData.chart = JSON.parse(chartData.chart);

          const currentInterval = getInterval(chartData);
          const currentIntervalTypeId = SquaberIntervalMap[currentInterval];

          this.shapeData.actualIntervalTypeId = currentIntervalTypeId;

          if (currentIntervalTypeId !== this.state.interval) {
            this.setState(
              { interval: currentIntervalTypeId },
              this.handleIntervalChange
            );
          }

          this.shapeData.preventDraw = !this.shouldDraw(
            "enable_squaber_shapes",
            drawnObjectsOptions
          );
          defaultChartTemplate = chartData.chart;

          if (currentInterval) {
            widgetOptions.interval = currentInterval;
          }
        } else if (
          chartTemplatesState.defaultTemplate &&
          accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS
        ) {
          defaultChartTemplate = chartTemplatesState.defaultTemplate.settings;
        } else {
          defaultChartTemplate = defaultStudyChartData;
        }

        const tvWidget = new Widget(widgetOptions);

        this.tvWidget = tvWidget;

        tvWidget.onChartReady(() => {
          SquaberTVDrawing._manageShapesRedraw(tvWidget, this.shapeData);
          this.tvWidgetReady = true;
          this.tvWidget = tvWidget;

          this.squaberTVMarkersController = new SquaberTVMarkersController(
            tvWidget,
            this.dataFeed,
            translate
          );

          if (dividends && dividends.length) {
            this.squaberTVMarkersController.setMarkersData(
              "dividends",
              this.getProcessedDividends()
            );
          }

          if (reportDates && reportDates.length) {
            this.squaberTVMarkersController.setMarkersData(
              "financialReports",
              reportDates
            );
          }

          if (mediaMonitorMessages && mediaMonitorMessages.length) {
            this.squaberTVMarkersController.setMarkersData(
              "mediaMonitorMessages",
              mediaMonitorMessages
            );
          }

          if (lastMinMaxPoints && lastMinMaxPoints.length) {
            this.squaberTVMarkersController.setMarkersData(
              "currentLastMinMaxPoints",
              this.getLastMinMaxPoints(true)
            );
            this.squaberTVMarkersController.setMarkersData(
              "previousLastMinMaxPoints",
              this.getLastMinMaxPoints()
            );
          }

          if (insiderTransactions && insiderTransactions.length) {
            this.squaberTVMarkersController.setMarkersData(
              "insiderTransactions",
              insiderTransactions
            );
          }

          tvWidget.subscribe("onSelectedLineToolChanged", () => {
            const accessLevel = getAccessLevel(userData);

            handleAccess(accessLevel, [
              accessLevelsMap.NO_PREMIUM_ACCESS,
              accessLevelsMap.PREMIUM_ACCESS_EXPIRED
            ]);
          });

          tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(null, this.handleIntervalChange);

          tvWidget.subscribe("onAutoSaveNeeded", () => {
            this.saveChart({}, true);
          });

          tvWidget.subscribe("undo_redo_state_changed", () => {
            this.saveChartDebouncer();
          });

          tvWidget
            .activeChart()
            .onSymbolChanged()
            .subscribe(null, () => {
              this.handleRefreshEnded();
            });

          tvWidget.subscribe("onMarkClick", markId => {
            const { hasPermission } = this.props;
            const { userData } = this.state;
            const { userIsNotLoggedIn } = userData ?? {};
            const mark = this.squaberTVMarkersController.getMarkerById(markId);

            if (mark.type === markerTypesNames.aggregatedMediaMonitorMessages) {
              const aggregatedMarkers = mark.markerMetaData;

              showModal("AggregatedMarkersModal", {
                aggregatedMarkers,
                userAccessOverride: hasPermission([
                  PLAN_PERMISSIONS_NAMES.TRADING_VIEW_CHART
                ])
              });
            } else if (mark.type === markerTypesNames.mediaMonitorMessages) {
              const {
                title,
                description,
                provider,
                attachments,
                is_premium,
                link
              } = mark.markerMetaData;

              let userHasAccessToContent = true;

              if (is_premium) {
                userHasAccessToContent = hasPermission([
                  PLAN_PERMISSIONS_SECTIONS.MEDIA_MONITOR
                ]);
              }

              if (userHasAccessToContent) {
                if (description) {
                  const helper = new MediaMonitorHelper(
                    description,
                    provider,
                    translate,
                    attachments
                  );

                  showModal("InterestingStockDescription", {
                    title,
                    content: helper.formatMediaMonitorEntry()
                  });
                } else if (link) {
                  window.open(link, "_blank");
                }
              } else if (userIsNotLoggedIn) {
                showModal("RegisterModal");
              } else {
                showModal("PremiumModal", {
                  name: "open a media monitor entry from the chart level"
                });
              }
            }
          });

          this.uploadChartTemplateToChart(defaultChartTemplate);

          this.timeouts.drawMarkersOnChart = setTimeout(() => {
            this.squaberTVMarkersController.drawMarkersOnChart(true);
          }, 2000);
        });

        tvWidget.headerReady().then(() => {
          this.setState({ tvWidgetHeaderReady: true });
          this.prepareHeader(tvWidget, shouldShowFullScreen);
        });

        refreshReportDates(stock.id);
        refreshDividends(stock.id);
        refreshPriceAlerts(stock.id);
      },
      () => {}
    );
  }

  prepareHeader(tvWidget, shouldShowFullScreen) {
    const { isMobile, hasPermission } = this.props;

    const hasPremium = hasPermission([
      PLAN_PERMISSIONS_NAMES.TRADING_VIEW_CHART
    ]);

    if (hasPremium) {
      this.modifyContextMenu(tvWidget);
    }

    if (shouldShowFullScreen || isMobile) {
      this.addStocksListButton(tvWidget);
    }

    this.addTemplateControlsButton(
      tvWidget,
      !hasPermission(PLAN_PERMISSIONS_SECTIONS.TEMPLATES_AND_ANALYTICS)
    );
    this.addAnalyticsControlsButton(
      tvWidget,
      !hasPermission(PLAN_PERMISSIONS_SECTIONS.TEMPLATES_AND_ANALYTICS)
    );
    this.addBackupsListButton(tvWidget, !hasPremium);
    this.addPriceAlertsButton(tvWidget, !hasPremium);

    this.addChartObjectsSettingsButton(tvWidget);
    this.addFullscreenButton(tvWidget, shouldShowFullScreen);
  }

  disableTooltipOption(button) {
    const { translate } = this.props;

    const title = button.getAttribute("title") ?? "";

    button.setAttribute(
      "title",
      `${title} ${translate("chart_tooltip_option_disabled_part_of_title")}`
    );
    button.addEventListener("click", () => {
      showModal("PremiumModal", { name: "chart disabled option" });
    });
    button.style.opacity = 0.4;
  }

  modifyContextMenu(tvWidget) {
    const { translate } = this.props;

    tvWidget.onContextMenu((unixtime, price) => [
      {
        text: translate("Add price alert"),
        position: "top",
        click: () => this.togglePriceAlertsVisible(price)
      },
      { text: "-", position: "top" }
    ]);
  }

  applyFontAwesomeButtonStyles(button) {
    button.style.width = "38px";
    button.style.height = "38px";
    button.style.cursor = "pointer";
    button.style.padding = "11px 9px";
    button.style.fontSize = "1em";
    button.style.overflow = "visible";
    button.style.boxSizing = "border-box";
  }

  addChartObjectsSettingsButton(tvWidget) {
    const { translate } = this.props;

    let button = tvWidget.createButton({ align: "right" });
    button.setAttribute("title", translate("Show objects options"));
    button.classList.add("apply-common-tooltip");

    const buttonIcon = `<svg style="height: 16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="sliders-h" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sliders-h fa-w-16 fa-2x"><path fill="currentColor" d="M504 384H192v-40c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h88v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h312c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-344 64h-32v-96h32v96zM504 96H256V56c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h152v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h248c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-280 64h-32V64h32v96zm280 80h-88v-40c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h312v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h88c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-120 64h-32v-96h32v96z" class=""></path></svg>`;

    this.applyFontAwesomeButtonStyles(button);

    button.innerHTML = buttonIcon;
    button.addEventListener("click", () => {
      this.toggleDrawnObjectsOptionsVisible();
    });
  }

  addFullscreenButton(tvWidget, isFullscreen) {
    const { translate, isMobile } = this.props;

    if (isMobile) {
      return false;
    }

    let buttonIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor"><path d="M21 7v4h1V6h-5v1z"></path><path d="M16.854 11.854l5-5-.708-.708-5 5zM7 7v4H6V6h5v1z"></path><path d="M11.146 11.854l-5-5 .708-.708 5 5zM21 21v-4h1v5h-5v-1z"></path><path d="M16.854 16.146l5 5-.708.708-5-5z"></path><g><path d="M7 21v-4H6v5h5v-1z"></path><path d="M11.146 16.146l-5 5 .708.708 5-5z"></path></g></g></svg>`;

    let button = tvWidget.createButton({ align: "right" });
    button.setAttribute("title", translate("Fullscreen"));
    button.style.cursor = "pointer";
    button.style.padding = "0 2px";
    button.innerHTML = buttonIcon;
    button.addEventListener("click", () => {
      this.toggleFullscreen(isFullscreen);
    });
  }

  toggleFullscreen(isFullscreen) {
    const {
      locale,
      stock,
      history: { push },
      setAdvancedChartVisible,
      marketTranslations
    } = this.props;

    const urlParams = {
      locale,
      marketId: getTranslatedMarket(locale, stock.market, marketTranslations),
      stockId: stock.ticker.toLowerCase()
    };

    const searchParams = window.location.search
      ? qs.parse(window.location.search, { ignoreQueryPrefix: true })
      : {};

    let url = UrlProvider.getUrl(
      isFullscreen ? "fe.stockPage" : "fe.fullScreenChart",
      urlParams
    );

    if (isFullscreen && searchParams.return_to) {
      url = searchParams.return_to;
    }

    if (isFullscreen) {
      setAdvancedChartVisible(false);

      this.setState({
        shouldShowFullScreen: false
      });
    }

    push(url);
  }

  addTemplateControlsButton(tvWidget, disabled) {
    const { translate } = this.props;

    let button = tvWidget.createButton({ align: "right" });
    button.setAttribute("title", translate("Chart templates"));
    button.classList.add("apply-common-tooltip");

    const buttonIcon = `<svg style="height: 16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="analytics" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-analytics fa-w-18 fa-2x"><path fill="currentColor" d="M80 352H16c-8.84 0-16 7.16-16 16v128c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V368c0-8.84-7.16-16-16-16zM64 480H32v-96h32v96zm496-288h-64c-8.84 0-16 7.16-16 16v288c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16zm-16 288h-32V224h32v256zM502.77 88.68C510.12 93.24 518.71 96 528 96c26.51 0 48-21.49 48-48S554.51 0 528 0s-48 21.49-48 48c0 5.51 1.12 10.71 2.83 15.64l-89.6 71.68c-7.35-4.57-15.94-7.33-25.23-7.33s-17.88 2.76-25.23 7.33l-89.6-71.68C254.88 58.72 256 53.51 256 48c0-26.51-21.49-48-48-48s-48 21.49-48 48c0 7.4 1.81 14.32 4.8 20.58L68.58 164.8C62.32 161.81 55.4 160 48 160c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-7.4-1.81-14.32-4.8-20.58l96.22-96.22C193.68 94.19 200.6 96 208 96c9.29 0 17.88-2.76 25.23-7.33l89.6 71.68c-1.71 4.93-2.83 10.14-2.83 15.65 0 26.51 21.49 48 48 48s48-21.49 48-48c0-5.51-1.12-10.72-2.83-15.65l89.6-71.67zM528 32c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 224c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zM208 64c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm160 128c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-128 0h-64c-8.84 0-16 7.16-16 16v288c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16zm-16 288h-32V224h32v256zm176-160h-64c-8.84 0-16 7.16-16 16v160c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V336c0-8.84-7.16-16-16-16zm-16 160h-32V352h32v128z" class=""></path></svg>`;
    this.applyFontAwesomeButtonStyles(button);

    button.innerHTML = buttonIcon;

    if (disabled) {
      this.disableTooltipOption(button);
      return;
    }

    button.addEventListener("click", () => {
      this.toggleTemplatesOptionsVisible();
    });
  }

  addAnalyticsControlsButton(tvWidget, disabled) {
    const { translate } = this.props;

    let button = tvWidget.createButton({ align: "right" });
    button.setAttribute("title", translate("Analytics"));

    const buttonIcon = `<svg style="height: 16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M513.6 202.8l-19.2-25.6-48 36 19.2 25.6 48-36zM576 192c13.3 0 25.6-4 35.8-10.9 6.8-4.6 12.7-10.5 17.3-17.3C636 153.6 640 141.3 640 128c0-13.3-4-25.6-10.9-35.8-2.3-3.4-4.9-6.6-7.8-9.5-2.9-2.9-6.1-5.5-9.5-7.8C601.6 68 589.3 64 576 64s-25.6 4-35.8 10.9c-6.8 4.6-12.7 10.5-17.3 17.3C516 102.4 512 114.7 512 128c0 35.3 28.7 64 64 64zm0-96c17.6 0 32 14.4 32 32s-14.4 32-32 32-32-14.4-32-32 14.4-32 32-32zM99.8 250.9C89.6 244 77.3 240 64 240s-25.6 4-35.8 10.9c-6.8 4.6-12.7 10.5-17.3 17.3C4 278.4 0 290.7 0 304c0 35.3 28.7 64 64 64s64-28.7 64-64c0-13.3-4-25.6-10.9-35.8-4.6-6.8-10.5-12.7-17.3-17.3zM64 336c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm88-16h48v-32h-48v32zm469.3 82.7c-2.9-2.9-6.1-5.5-9.5-7.8C601.6 388 589.3 384 576 384s-25.6 4-35.8 10.9c-3.3 2.2-6.3 4.7-9.1 7.5l-91.8-55.1c5.6-13.3 8.7-28 8.7-43.3 0-61.9-50.1-112-112-112-11.3 0-21.9 2.2-32.2 5.2l-39.3-84.1C278.8 101.4 288 83.9 288 64c0-13.3-4-25.6-10.9-35.8-4.6-6.8-10.5-12.7-17.3-17.3C249.6 4 237.3 0 224 0s-25.6 4-35.8 10.9c-6.8 4.6-12.7 10.5-17.3 17.3C164 38.4 160 50.7 160 64c0 35.3 28.7 64 64 64 4 0 7.9-.5 11.7-1.2l39 83.6c-30.5 20-50.7 54.4-50.7 93.6 0 61.9 50.1 112 112 112 35 0 65.8-16.4 86.4-41.5l92.4 55.4c-1.7 5.8-2.7 11.8-2.7 18.1 0 35.3 28.7 64 64 64 13.3 0 25.6-4 35.8-10.9 6.8-4.6 12.7-10.5 17.3-17.3C636 473.6 640 461.3 640 448c0-13.3-4-25.6-10.9-35.8-2.3-3.4-5-6.6-7.8-9.5zM224 96c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm112 288c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80zm240 96c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32z"/></svg>`;
    this.applyFontAwesomeButtonStyles(button);

    button.innerHTML = buttonIcon;

    if (disabled) {
      this.disableTooltipOption(button);
      return;
    }

    button.addEventListener("click", () => {
      this.toggleAnalyticsOptionsVisible();
    });
  }

  addBackupsListButton(tvWidget, disabled) {
    const { translate } = this.props;

    let button = tvWidget.createButton({ align: "right" });
    button.setAttribute("title", translate("Chart backups"));
    button.classList.add("apply-common-tooltip");

    const buttonIcon = `<svg style="height: 16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="save" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-save fa-w-14 fa-2x"><path fill="currentColor" d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM288 64v96H96V64h192zm128 368c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h16v104c0 13.255 10.745 24 24 24h208c13.255 0 24-10.745 24-24V64.491a15.888 15.888 0 0 1 7.432 4.195l83.882 83.882A15.895 15.895 0 0 1 416 163.882V432zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 144c-30.879 0-56-25.121-56-56s25.121-56 56-56 56 25.121 56 56-25.121 56-56 56z" class=""></path></svg>`;
    this.applyFontAwesomeButtonStyles(button);

    button.innerHTML = buttonIcon;

    if (disabled) {
      this.disableTooltipOption(button);
      return;
    }

    button.addEventListener("click", () => {
      this.toggleBackupsListVisible();
    });
  }

  addStocksListButton(tvWidget) {
    const { translate } = this.props;

    let button = tvWidget.createButton({ align: "right" });
    button.setAttribute("title", translate("Select stock"));
    button.classList.add("apply-common-tooltip");

    const buttonIcon = `<svg style="height: 16px;" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-search fa-w-16 fa-2x"><path fill="currentColor" d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z" class=""></path></svg>`;
    this.applyFontAwesomeButtonStyles(button);

    button.innerHTML = buttonIcon;
    button.addEventListener("click", () => {
      this.toggleStocksListVisible();
    });
  }

  addPriceAlertsButton(tvWidget, disabled) {
    const { translate } = this.props;

    let button = tvWidget.createButton({ align: "right" });
    button.setAttribute("title", translate("Price Alerts"));
    button.classList.add("apply-common-tooltip");

    const buttonIcon = `<svg style="height: 16px;" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="alarm-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-alarm-plus fa-w-16 fa-2x"><path fill="currentColor" d="M344 272h-72v-72a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v72h-72a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h72v72a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8v-72h72a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8zM32 112a80.09 80.09 0 0 1 80-80 79.23 79.23 0 0 1 50 18 253.22 253.22 0 0 1 34.44-10.8C175.89 15.42 145.86 0 112 0A112.14 112.14 0 0 0 0 112c0 25.86 9.17 49.41 24 68.39a255.93 255.93 0 0 1 17.4-31.64A78.94 78.94 0 0 1 32 112zM400 0c-33.86 0-63.89 15.42-84.44 39.25A253.22 253.22 0 0 1 350 50.05a79.23 79.23 0 0 1 50-18 80.09 80.09 0 0 1 80 80 78.94 78.94 0 0 1-9.36 36.75A255.93 255.93 0 0 1 488 180.39c14.79-19 24-42.53 24-68.39A112.14 112.14 0 0 0 400 0zM256 64C132.29 64 32 164.29 32 288a222.89 222.89 0 0 0 54.84 146.54L34.34 487a8 8 0 0 0 0 11.32l11.31 11.31a8 8 0 0 0 11.32 0l52.49-52.5a223.21 223.21 0 0 0 293.08 0L455 509.66a8 8 0 0 0 11.32 0l11.31-11.31a8 8 0 0 0 0-11.32l-52.5-52.49A222.89 222.89 0 0 0 480 288c0-123.71-100.29-224-224-224zm0 416c-105.87 0-192-86.13-192-192S150.13 96 256 96s192 86.13 192 192-86.13 192-192 192z" class=""></path></svg>`;
    this.applyFontAwesomeButtonStyles(button);

    button.innerHTML = buttonIcon;

    if (disabled) {
      this.disableTooltipOption(button);
      return;
    }

    button.addEventListener("click", () => {
      this.togglePriceAlertsVisible();
    });
  }

  extractTemplateFromCurrentChartSettings(
    settingsCallback = () => {},
    skipFilteringChartSource = false
  ) {
    const { token, drawnObjectsOptions } = this.state;

    const userData = this.getUserData();

    const accessLevel =
      (userData ? userData.userAccessType : null) ||
      getAccessLevel(token || userData);

    this.tvWidget.save(chart => {
      handleAccess(accessLevel);

      if (accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS) {
        if (!skipFilteringChartSource) {
          chart.charts[0].panes.forEach(pane => {
            // Allow only given source types to be return
            // Commit message - Fixed issue with loading indicators drawn on price bars
            pane.sources = pane.sources.filter(source =>
              [
                "Study",
                "MainSeries",
                "study_ScriptWithDataOffset",
                "study_Volume"
              ].includes(source.type)
            );
          });
        }

        settingsCallback({ chart, drawnObjectsOptions });
      } else {
        settingsCallback(null);
      }
    });
  }

  getLastMinMaxPoints(active = false) {
    const { lastMinMaxPoints } = this.props;

    if (active) {
      return lastMinMaxPoints.current;
    }

    return lastMinMaxPoints.previous;
  }

  uploadChartTemplateToChart(settings) {
    if (typeof settings.drawnObjectsOptions !== "undefined") {
      this.setState({ drawnObjectsOptions: settings.drawnObjectsOptions });
    }

    const {
      reportDates,
      mediaMonitorMessages,
      insiderTransactions,
      stock
    } = this.props;
    const { drawnObjectsOptions } = this.state;

    this.tvWidget.save(chart => {
      const finalChartData = mergeDefaultTemplateWithCurrentChart(
        settings.chart || settings,
        chart,
        stock.ticker
      );

      this.shapeData.preventDraw = !this.shouldDraw(
        "enable_squaber_shapes",
        settings.drawnObjectsOptions
      );
      SquaberTVDrawing._redrawShapes(this.tvWidget, this.shapeData);

      this.tvWidget.load(finalChartData);
      this.chartTemplateUploaded = true;

      if (this.squaberTVMarkersController && this.chartTemplateUploaded) {
        this.squaberTVMarkersController
          .setDrawnObjectsOptions(drawnObjectsOptions)
          .setMarkersData("dividends", this.getProcessedDividends())
          .setMarkersData("financialReports", reportDates)
          .setMarkersData("mediaMonitorMessages", mediaMonitorMessages)
          .setMarkersData("insiderTransactions", insiderTransactions)
          .setMarkersData(
            "currentLastMinMaxPoints",
            this.getLastMinMaxPoints(true)
          )
          .setMarkersData(
            "previousLastMinMaxPoints",
            this.getLastMinMaxPoints()
          )
          .drawMarkersOnChart(true);
      }

      for (let pane of this.tvWidget.chart().getPanes()) {
        const mainSourcePriceScale = pane.getMainSourcePriceScale();

        if (mainSourcePriceScale) {
          const mode = mainSourcePriceScale.getMode();

          if (mode !== 0) {
            mainSourcePriceScale.setMode(0);
            mainSourcePriceScale.setMode(mode);
          }
        }
      }
    });
  }

  uploadChartBackupToChart({ chart, settings = [] }) {
    const { stock } = this.props;
    const hasSettings = settings.length > 0 && this.squaberTVMarkersController;
    if (hasSettings) {
      this.updateDrawnObjectsOptions(
        this.addLabelsToDrawnObjectsOptions(settings),
        false
      );
    }

    chart.charts[0].panes = chart.charts[0].panes.map(pane => {
      pane.sources = pane.sources.map(source => {
        if (
          source.state.name === MA_STUDY_NAME ||
          source.state.name === EMA_STUDY_NAME
        ) {
          return alignMovingAverageOptions(source);
        }

        if (source.type === "MainSeries") {
          if (source.state.symbol) {
            source.state.symbol = stock.ticker;
          }

          if (source.state.shortName) {
            source.state.shortName = stock.ticker;
          }
        }

        return source;
      });

      return pane;
    });

    if (chart) {
      this.tvWidget.load(chart);
    }
  }

  addLabelsToDrawnObjectsOptions(settings = []) {
    return this.state.drawnObjectsOptions.map(option => {
      const newOption = settings.find(
        newOption => newOption.key === option.key
      );

      const newValue =
        newOption && typeof newOption.value !== "undefined"
          ? newOption.value
          : option.value;

      return { ...option, value: newValue };
    });
  }

  saveChart(additionalData = {}) {
    const {
      stock,
      tvChartUserDataRoutinePromiseCreator,
      hasPermission
    } = this.props;
    const userData = this.getUserData();

    const { token } = this.state;

    const accessLevel =
      (userData ? userData.userAccessType : null) ||
      getAccessLevel(token || userData);

    if (!this.tvWidget) {
      return;
    }

    const newAdditionalData = {
      settings: this.state.drawnObjectsOptions.map(option => ({
        key: option.key,
        value: option.value
      })),
      ...additionalData
    };

    this.tvWidget.save(chart => {
      handleAccess(accessLevel, [
        accessLevelsMap.NO_PREMIUM_ACCESS,
        accessLevelsMap.PREMIUM_ACCESS_EXPIRED
      ]);

      if (hasPermission([PLAN_PERMISSIONS_NAMES.TRADING_VIEW_CHART])) {
        tvChartUserDataRoutinePromiseCreator({
          stockId: stock.id,
          chart: JSON.stringify(chart),
          additionalData: newAdditionalData
        }).then(data =>
          this.setState({
            backupsList: data.backups
          })
        );
      }
    });
  }

  shouldDraw(optionKey, providedOptions = null) {
    const options = providedOptions || this.state.drawnObjectsOptions;

    return options.find(option => option.key === optionKey)?.value ?? true;
  }

  drawPriceAlertsOnChart() {
    const { priceAlerts, translate, stock } = this.props;

    if (!priceAlerts || !this.tvWidget || !this.tvWidgetReady) {
      return;
    }

    const chart = this.tvWidget.chart();

    for (let drawnPriceAlert of this.drawnPriceAlerts.filter(
      priceAlert => !!priceAlert
    )) {
      if (typeof drawnPriceAlert === "object") {
        drawnPriceAlert.remove();
      } else {
        chart.removeEntity(drawnPriceAlert, { disableUndo: true });
      }
    }

    this.drawnPriceAlerts = [];

    if (!this.shouldDraw("show_price_alerts")) {
      return true;
    }

    for (let priceAlert of priceAlerts) {
      if (priceAlert.removedAt) {
        continue;
      }

      try {
        const positionLine = chart.createPositionLine();

        positionLine.setPrice(Number(priceAlert.price));
        positionLine.setQuantity();
        positionLine.setLineColor("#f2c200");
        positionLine.setText("");
        positionLine.setBodyFont("bold 14pt Verdana");

        if (priceAlert.sent_date) {
          positionLine.setLineColor("#909193");
          positionLine.setBodyTextColor("#909193");
          positionLine.setBodyBorderColor("#909193");
        }

        this.drawnPriceAlerts.push(positionLine);
      } catch (e) {
        this.drawnPriceAlerts.push(false);
      }

      if (priceAlert.sent_date) {
        const shapeId = chart.createShape(
          {
            time: moment(priceAlert.sent_date).unix(),
            price: Number(priceAlert.price)
          },
          {
            shape: "note",
            text: translate("Executed price alert: %{price}; %{hour} %{day}", {
              price: new Price({
                value: priceAlert.price,
                currency: stock.currency
              }).render(),
              day: moment(priceAlert.sent_date).format("DD.MM.YYYY"),
              hour: moment(priceAlert.sent_date).format("HH:mm")
            }),
            disableSave: true,
            disableUndo: true,
            showInObjectsTree: false,
            disableSelection: false,
            lock: true,
            zOrder: "bottom",
            overrides: {
              editable: false,
              fixedSize: false,
              markerColor: theme.colors.baseGrey,
              backgroundColor: "#FFFFFF",
              textColor: "#000000"
            }
          }
        );

        this.drawnPriceAlerts.push(shapeId);
      }
    }

    const hasError =
      this.drawnPriceAlerts.findIndex(
        e => typeof e === "undefined" || e === false
      ) !== -1;

    return !hasError;
  }

  getProcessedDividends() {
    const { dividends } = this.props;

    let processedDividends = [];

    dividends.forEach(dividend => {
      const processedDividendIndex = processedDividends.findIndex(
        processedDividend =>
          processedDividend.dividend_day === dividend.dividend_day
      );
      if (processedDividendIndex === -1) {
        processedDividends.push({
          ...dividend,
          payouts: [
            {
              day: dividend.payout_day,
              payout: dividend.payout
            }
          ]
        });
      } else {
        processedDividends[processedDividendIndex].has_multiple_payouts = true;
        processedDividends[processedDividendIndex].payouts.push({
          day: dividend.payout_day,
          payout: dividend.payout
        });
      }
    });

    return processedDividends;
  }

  toggleDrawnObjectsOptionsVisible() {
    this.setState({
      ...this.defaultOptionsVisibility,
      drawnObjectsOptionsVisible: !this.state.drawnObjectsOptionsVisible
    });
  }

  toggleTemplatesOptionsVisible() {
    this.setState({
      ...this.defaultOptionsVisibility,
      templatesOptionsVisible: !this.state.templatesOptionsVisible
    });
  }

  toggleAnalyticsOptionsVisible() {
    this.setState({
      ...this.defaultOptionsVisibility,
      analyticsOptionsVisible: !this.state.analyticsOptionsVisible
    });
  }

  toggleStocksListVisible() {
    this.setState({
      ...this.defaultOptionsVisibility,
      stocksListVisible: !this.state.stocksListVisible
    });
  }

  toggleBackupsListVisible() {
    this.setState({
      ...this.defaultOptionsVisibility,
      backupsListVisible: !this.state.backupsListVisible
    });
  }

  togglePriceAlertsVisible(price) {
    this.setState({
      ...this.defaultOptionsVisibility
    });

    showModal("PriceAlertsModal", {
      stockId: this.props.stock.id,
      price
    });
  }

  updateDrawnObjectsOptions(newOptions, saveChartAfterUpdate = true) {
    this.setState(
      {
        drawnObjectsOptions: newOptions
      },
      () => {
        if (!saveChartAfterUpdate) return;
        this.saveChart({ settings: newOptions });
      }
    );
    this.shapeData.preventDraw = !this.shouldDraw(
      "enable_squaber_shapes",
      newOptions
    );
    SquaberTVDrawing._redrawShapes(this.tvWidget, this.shapeData);
  }

  getDrawnObjectsOptionsBaseOnInterval = interval => {
    const { drawnObjectsOptions } = this.state;

    return drawnObjectsOptions.map(option => {
      const newOption = option;
      const enabledIntervals = option?.enabled_intervals;

      let isDisabled = false;
      let disabledMessage = `option_tooltip_enabled_only_on_interval`;

      if (isArray(enabledIntervals)) {
        isDisabled = !enabledIntervals.includes(interval);
        disabledMessage += `_${enabledIntervals.join("_")}`;
      }

      if (isDisabled) {
        if (!!disabledMessage) newOption.disabledMessage = disabledMessage;
        newOption.disabled = true;
      } else {
        delete newOption.disabled;
        delete newOption.disabledMessage;
      }

      return newOption;
    });
  };

  handleIntervalChange = () => {
    const { interval } = this.state;
    const { stock, fetchReportDates, reportDates } = this.props;

    // Assume countBack is possible on new interval
    this.dataFeed.countBackPossible = true;

    switch (interval) {
      case SquaberIntervalTypes.daily:
      case SquaberIntervalTypes.weekly: {
        if (isEmpty(reportDates)) {
          fetchReportDates(stock.id);
        }
      }
    }

    this.setState({ ...this.defaultOptionsVisibility });
  };

  refreshChart() {
    this.handleRefreshStarted();

    if (this.timeouts?.drawMarkersOnChart) {
      clearTimeout(this.timeouts?.drawMarkersOnChart);
    }

    let {
      tvChartRoutinePromiseCreator,
      tvChartGetUserDataRoutinePromiseCreator,
      stock,
      latestQuoteDatetimeUtc,
      userData,
      chartTemplatesState,
      reportDates,
      dividends,
      insiderTransactions,
      translate,
      mediaMonitorMessages,
      clearMediaMonitorEntries,
      clearLastMinMaxPoints,
      clearInsiderTransactions,
      lastMinMaxPoints,
      refreshDividends,
      refreshPriceAlerts,
      refreshReportDates
    } = this.props;

    const chart = this.tvWidget.chart();

    chart.removeAllShapes();
    chart.removeAllStudies();
    const shapes = chart.getAllShapes();

    if (shapes.length) {
      for (let shape of shapes) {
        chart.removeEntity(shape.entityId, { disableUndo: true });
      }
    }

    this.shapeData.shapes = {};

    clearMediaMonitorEntries();
    clearLastMinMaxPoints();
    clearInsiderTransactions();

    refreshDividends(stock.id);
    refreshReportDates(stock.id);
    refreshPriceAlerts(stock.id);

    const { drawnObjectsOptions } = this.state;

    const urlParams = window.location.search
      ? qs.parse(window.location.search.replace("?", ""))
      : {};

    let token = urlParams.auth_token;
    let userAccessType = urlParams.userAccessType;

    if (token) {
      userData = {
        token,
        userAccessType
      };
    }

    let intervals = config.tradingViewOptions.defaultMarketsIntervals;

    const specificMarketIntervals = config.tradingViewOptions.marketsIntervals.find(
      marketInterval => {
        return (
          marketInterval.markets.findIndex(
            market => market === stock.market.toLowerCase()
          ) !== -1
        );
      }
    );

    if (specificMarketIntervals) {
      intervals = specificMarketIntervals.intervals;
    }

    const shapeManager = new SquaberTVShapeManager(
      this.shapeData.shapes,
      stock
    );

    this.dataFeed.setDataFeed(
      stock,
      tvChartRoutinePromiseCreator,
      new SquaberTVQuotationNotifier(stock.id, false),
      shapeManager,
      translate,
      intervals,
      latestQuoteDatetimeUtc
    );

    const accessLevel =
      (userData ? userData.userAccessType : null) ||
      getAccessLevel(token || userData);

    tvChartGetUserDataRoutinePromiseCreator(stock.id).then(
      chartDataResponse => {
        let chartData = cloneDeep(chartDataResponse);

        if (chartData.type && typeof chartData.payload !== "undefined") {
          chartData = chartData.payload;
        }

        if (typeof chartData.settings === "string") {
          chartData.settings = JSON.parse(chartData.settings);
        }

        if (
          typeof chartData?.backups !== "undefined" &&
          Array.isArray(chartData.backups) &&
          chartData.backups.length > 0
        ) {
          this.setState({
            backupsList: chartData.backups
          });
        }

        if (chartData.settings) {
          this.setState({
            drawnObjectsOptions: this.addLabelsToDrawnObjectsOptions(
              chartData.settings
            )
          });
        }

        let defaultChartTemplate;

        if (chartData && chartData.chart) {
          if (typeof chartData.chart === "string") {
            chartData.chart = JSON.parse(chartData.chart);
          }

          const currentInterval = getInterval(chartData);
          const currentIntervalTypeId = SquaberIntervalMap[currentInterval];

          this.shapeData.actualIntervalTypeId = currentIntervalTypeId;

          if (currentIntervalTypeId !== this.state.interval) {
            this.setState(
              { interval: currentIntervalTypeId },
              this.handleIntervalChange
            );
          }

          this.shapeData.preventDraw = !this.shouldDraw(
            "enable_squaber_shapes",
            drawnObjectsOptions
          );
          defaultChartTemplate = chartData.chart;
        } else if (
          chartTemplatesState.defaultTemplate &&
          accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS
        ) {
          defaultChartTemplate = chartTemplatesState.defaultTemplate.settings;
        } else {
          defaultChartTemplate = defaultStudyChartData;
        }

        const tvWidget = this.tvWidget;

        const afterChartReady = () => {
          tvWidget.setSymbol(
            stock.ticker,
            getIntervalFromIntervalTypeId(this.shapeData.actualIntervalTypeId)
          );

          SquaberTVDrawing._manageShapesRedraw(tvWidget, this.shapeData);

          this.squaberTVMarkersController = new SquaberTVMarkersController(
            tvWidget,
            this.dataFeed,
            translate
          );

          if (dividends && dividends.length) {
            this.squaberTVMarkersController.setMarkersData(
              "dividends",
              this.getProcessedDividends()
            );
          }

          if (reportDates && reportDates.length) {
            this.squaberTVMarkersController.setMarkersData(
              "financialReports",
              reportDates
            );
          }

          if (mediaMonitorMessages && mediaMonitorMessages.length) {
            this.squaberTVMarkersController.setMarkersData(
              "mediaMonitorMessages",
              mediaMonitorMessages
            );
          }

          if (lastMinMaxPoints && lastMinMaxPoints.length) {
            this.squaberTVMarkersController.setMarkersData(
              "currentLastMinMaxPoints",
              this.getLastMinMaxPoints(true)
            );
            this.squaberTVMarkersController.setMarkersData(
              "previousLastMinMaxPoints",
              this.getLastMinMaxPoints()
            );
          }

          if (insiderTransactions && insiderTransactions.length) {
            this.squaberTVMarkersController.setMarkersData(
              "insiderTransactions",
              insiderTransactions
            );
          }

          this.uploadChartTemplateToChart(defaultChartTemplate);

          this.timeouts.drawMarkersOnChart = setTimeout(() => {
            this.squaberTVMarkersController.drawMarkersOnChart(true);
            this.setState({ chartRefreshing: false });
          }, 2000);
        };

        if (this.tvWidgetReady) {
          afterChartReady();
        } else {
          this.tvWidget.onChartReady(() => {
            afterChartReady();
          });
        }
      },
      () => {}
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      setAdvancedChartVisible,
      reportDates,
      mediaMonitorMessages,
      dividends,
      priceAlerts,
      insiderTransactions
    } = this.props;

    const { isLandscape: prevIsLandscape } = prevState;
    const { isLandscape, drawnObjectsOptions, interval } = this.state;

    const currentIntervalTypeId = this.shapeData.actualIntervalTypeId;

    const filteredDrawnObjectsOptions = this.getDrawnObjectsOptionsBaseOnInterval(
      currentIntervalTypeId
    );

    if (!!currentIntervalTypeId && !isEqual(currentIntervalTypeId, interval)) {
      this.setState(
        { interval: currentIntervalTypeId },
        this.handleIntervalChange
      );
    }

    if (!isEqual(this.props.stock, prevProps.stock)) {
      if (this.tvWidgetReady) {
        this.refreshChart();
      } else if (this.tvWidget) {
        this.tvWidget.onChartReady(() => {
          this.refreshChart();
        });
      } else {
        this.initChart();
      }
    }

    if (prevIsLandscape !== isLandscape && !isLandscape) {
      setAdvancedChartVisible(false);
    }

    if (!isEqual(dividends, prevProps.dividends)) {
      this.triggerDividendsRefresh();
    }
    if (!isEqual(reportDates, prevProps.reportDates)) {
      this.triggerReportDatesRefresh();
    }

    const hasChangedPriceAlerts = !isEqual(priceAlerts, prevProps.priceAlerts);
    const hasChangedShowPriceAlerts = !isEqual(
      this.state?.drawnObjectsOptions?.find(
        ({ key }) => key === "show_price_alerts"
      ),
      prevState?.drawnObjectsOptions?.find(
        ({ key }) => key === "show_price_alerts"
      )
    );

    if (this.tvWidgetReady) {
      this.triggerDividendsRefreshProvider();
      this.triggerPriceAlertRefreshProvider();
      this.triggerReportDatesRefreshProvider();
    }

    if (hasChangedPriceAlerts || hasChangedShowPriceAlerts) {
      this.triggerPriceAlertRefresh();
    }

    if (this.squaberTVMarkersController && this.chartTemplateUploaded) {
      this.squaberTVMarkersController
        .setDrawnObjectsOptions(filteredDrawnObjectsOptions)
        .setMarkersData("mediaMonitorMessages", mediaMonitorMessages)
        .setMarkersData(
          "currentLastMinMaxPoints",
          this.getLastMinMaxPoints(true)
        )
        .setMarkersData("previousLastMinMaxPoints", this.getLastMinMaxPoints())
        .setMarkersData("insiderTransactions", insiderTransactions)
        .drawMarkersOnChart(true);
    }

    if (!isEqual(filteredDrawnObjectsOptions, drawnObjectsOptions)) {
      this.setState({ drawnObjectsOptions: filteredDrawnObjectsOptions });
    }
  }

  triggerDividendsRefresh = () => {
    this.setState({ dividendsUpdating: true });
  };

  triggerDividendsRefreshProvider = () => {
    const { dividendsUpdating } = this.state;
    const { dividendsLoading } = this.props;

    // Check if in process of updating
    if (dividendsUpdating !== true || dividendsLoading !== false) {
      return;
    }

    // Required for update
    if (!this.squaberTVMarkersController) {
      return;
    }

    this.squaberTVMarkersController
      .setMarkersData("dividends", this.getProcessedDividends())
      .drawMarkersOnChart(true);

    // Finish updating
    this.setState({ dividendsUpdating: false });
  };

  triggerReportDatesRefresh = () => {
    this.setState({ reportDatesUpdating: true });
  };

  triggerReportDatesRefreshProvider = () => {
    const { reportDatesUpdating } = this.state;
    const { reportDates, reportDatesLoading } = this.props;

    // Check if in process of updating
    if (reportDatesUpdating !== true || reportDatesLoading !== false) {
      return;
    }

    // Required for update
    if (!this.squaberTVMarkersController) {
      return;
    }

    this.squaberTVMarkersController
      .setMarkersData("financialReports", reportDates)
      .drawMarkersOnChart(true);

    // Finish updating
    this.setState({ reportDatesUpdating: false });
  };

  triggerPriceAlertRefresh = () => {
    this.setState({ priceAlertUpdating: true });
  };

  triggerPriceAlertRefreshProvider = () => {
    const { priceAlertUpdating, chartRefreshing } = this.state;
    const { priceAlertsLoading } = this.props;

    // Check if in process of updating
    if (priceAlertUpdating !== true || priceAlertsLoading !== false) {
      return;
    }

    if (
      this.tvWidgetReady === false ||
      this.tvWidget === false ||
      chartRefreshing
    ) {
      return;
    }

    const hasDrawn = this.drawPriceAlertsOnChart();

    if (!hasDrawn) {
      return;
    }

    this.setState({ priceAlertUpdating: false });
  };

  componentWillUnmount() {
    if (this.tvWidget !== null && this.tvWidgetReady) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }

    for (const timeout of Object.values(this.timeouts)) {
      if (timeout) {
        clearTimeout(timeout);
      }
    }

    this.handleRefreshEnded.cancel();

    this.hideActionInProgress();
    this.props.rejectChartUserDataRoutinePromise();
  }

  render() {
    const {
      isMobile,
      translate,
      setAdvancedChartVisible,
      advancedChartVisible,
      stock,
      history: { push }
    } = this.props;

    const {
      shouldShowFullScreen,
      drawnObjectsOptionsVisible,
      templatesOptionsVisible,
      analyticsOptionsVisible,
      stocksListVisible,
      backupsListVisible,
      backupsList,
      drawnObjectsOptions,
      userData
    } = this.state;

    const shouldShowAdvancedChart =
      advancedChartVisible || window.ReactNativeWebView;

    const shouldShowChart = !isMobile || shouldShowAdvancedChart;
    const shouldShowMobileChart = isMobile && shouldShowAdvancedChart;
    const isOnFullScreen = shouldShowFullScreen || shouldShowMobileChart;

    return (
      <div
        id="section-tv-chart"
        className={classNames({
          "tv-chart-wrapper": true,
          "is-on-full-screen": isOnFullScreen
        })}
      >
        {isMobile ? (
          <React.Fragment>
            <CustomSimpleChart
              showVolumeStudy={true}
              stockId={stock.id}
              key={stock.id}
            />
          </React.Fragment>
        ) : null}
        <div
          id={this.props.containerId}
          className={classNames({
            "tv-chart-container": true,
            visible: shouldShowChart,
            "full-screen": isOnFullScreen
          })}
        />
        {isMobile && advancedChartVisible ? (
          <div
            className="disable-fullscreen-button"
            onClick={() => {
              if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage("ADVANCED_CHART_CLOSE");
              } else if (isMobile) {
                const searchParams = window.location.search
                  ? qs.parse(window.location.search, {
                      ignoreQueryPrefix: true
                    })
                  : {};
                setAdvancedChartVisible(false);

                if (searchParams.return_to) {
                  push(searchParams.return_to);
                }
              }
            }}
          >
            <i className="far fa-compress-arrows-alt" />
          </div>
        ) : null}
        {drawnObjectsOptionsVisible ? (
          <TVChartDrawnObjectsOptions
            drawnObjectsOptions={drawnObjectsOptions}
            updateDrawnObjectsOptions={this.updateDrawnObjectsOptions}
            close={this.toggleDrawnObjectsOptionsVisible}
          />
        ) : null}
        {templatesOptionsVisible ? (
          <TVChartTemplatesOptions
            uploadChartTemplateToChart={this.uploadChartTemplateToChart}
            extractTemplateFromCurrentChartSettings={
              this.extractTemplateFromCurrentChartSettings
            }
            close={this.toggleTemplatesOptionsVisible}
          />
        ) : null}
        {analyticsOptionsVisible ? (
          <TVChartAnalysisOptions
            uploadChartTemplateToChart={this.uploadChartBackupToChart}
            extractTemplateFromCurrentChartSettings={
              this.extractTemplateFromCurrentChartSettings
            }
            close={this.toggleAnalyticsOptionsVisible}
          />
        ) : null}
        {stocksListVisible ? (
          <TVChartStocksList
            close={this.toggleStocksListVisible}
            userData={userData}
          />
        ) : null}
        {backupsListVisible ? (
          <TVChartBackupList
            close={this.toggleBackupsListVisible}
            translate={translate}
            backupsList={backupsList}
            tvWidget={this.tvWidget}
            uploadChartBackupToChart={this.uploadChartBackupToChart}
          />
        ) : null}
        <a href="https://pl.tradingview.com/" target="_blank" rel={"nofollow"}>
          {translate("Charts by TradingView")}
        </a>
      </div>
    );
  }
}

export default TVChart;
