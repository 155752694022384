import { isArray, isEmpty } from "lodash";
import { fork, put, select, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import decisionModal from "../../../lib/decisionModal";
import { actions as alertsActions } from "../alerts";
import {
  getHasPermission,
  PLAN_PERMISSIONS_NAMES
} from "../auth/userPermissions/selector";
import type { ChartTemplatePayload } from "./dto/toChartTemplateDetails";
import {
  toBackendChartTemplate,
  toFrontendChartTemplateDetails
} from "./dto/toChartTemplateDetails";
import {
  addChartTemplateRoutine,
  fetchChartTemplateDetailsRoutine,
  fetchChartTemplatesRoutine,
  removeChartTemplateRoutine,
  updateChartTemplateRoutine
} from "./index";

function* showSuccessfullySavedAlert() {
  yield put(
    alertsActions.addSuccessAlert({
      title: "Successfully saved chart template"
    })
  );
}

function* showErrorWhenSavedAlert() {
  yield put(
    alertsActions.addErrorAlert({
      title: "There was an error when saving your chart template"
    })
  );
}

function* showOperationAbortedAlert() {
  yield put(
    alertsActions.addInfoAlert({
      title: "Operation aborted"
    })
  );
}

function* showSuccessfullyRemovedAlert() {
  yield put(
    alertsActions.addSuccessAlert({
      title: "Successfully removed chart template"
    })
  );
}

function* showSaveDecisionModal() {
  return yield decisionModal(
    "ConfirmModal",
    {
      title: "Are you sure?",
      message: "Are you sure you want to save current settings as default?"
    },
    ["confirm", "cancel"]
  );
}

function* showRemoveDecisionModal() {
  return yield decisionModal(
    "ConfirmModal",
    {
      title: "Are you sure?",
      message: "Are you sure you want to remove default chart template?"
    },
    ["confirm", "cancel"],
    {
      confirm: "Delete template"
    }
  );
}

function* showErrorWhenFetchChartTemplateDetails() {
  yield put(
    alertsActions.addErrorAlert({
      title: "There was an error when applying your chart template"
    })
  );
}

function* fetchChartTemplates() {
  try {
    const payload = { templatesList: [] };

    const hasPermission = yield select(getHasPermission);

    if (!hasPermission([PLAN_PERMISSIONS_NAMES.TRADING_VIEW_CHART])) {
      yield put(fetchChartTemplatesRoutine.success(payload));
      return;
    }

    let { data } = yield ApiClient.get({
      urlPath: "app.chartTemplates.list",
      skipAccessCheck: true
    });

    payload.templatesList = data;

    if (data.length) {
      const defaultTemplate = payload.templatesList.find(
        template => template.is_default
      );

      if (defaultTemplate) {
        try {
          const { data: defaultTemplateData } = yield ApiClient.get({
            urlPath: "app.chartTemplates.singleTemplate",
            variables: {
              id: defaultTemplate.id
            }
          });

          const { data: defaultTemplateSettings } = yield ApiClient.get({
            url: defaultTemplateData.url,
            skipAuthorizationHeader: true
          });

          const transformedData = toFrontendChartTemplateDetails(
            defaultTemplateSettings
          );

          if (typeof transformedData === "undefined") {
            throw Error("Failed to load default template");
          }

          defaultTemplateData.settings = transformedData;

          payload.defaultTemplate = defaultTemplateData;
        } catch (e) {
          console.error("Failed to load default template");
        }
      }
    }

    yield put(fetchChartTemplatesRoutine.success(payload));
  } catch (error) {
    console.error(error);
    yield put(fetchChartTemplatesRoutine.failure());
  }
}

function* addChartTemplate({ payload }: { payload: ChartTemplatePayload }) {
  const transformedData = toBackendChartTemplate(payload);

  try {
    yield ApiClient.post({
      urlPath: "app.chartTemplates.list",
      data: transformedData
    });

    yield showSuccessfullySavedAlert();
    yield put(fetchChartTemplatesRoutine());
    yield put(addChartTemplateRoutine.success());
  } catch (error) {
    yield showErrorWhenSavedAlert();

    const errors = error?.response?.data?.non_field_errors;

    if (isArray(errors) && !isEmpty(errors)) {
      yield put(addChartTemplateRoutine.failure(errors[0]));
    } else {
      yield put(addChartTemplateRoutine.failure());
    }
  }
}

type UpdateChartTemplatePayload = { id: number } & ChartTemplatePayload;

function* updateChartTemplate({
  payload
}: {
  payload: UpdateChartTemplatePayload
}) {
  const { id } = payload;

  const transformedData = toBackendChartTemplate(payload);

  try {
    yield ApiClient.patch({
      urlPath: "app.chartTemplates.singleTemplate",
      variables: { id },
      data: transformedData
    });

    yield showSuccessfullySavedAlert();
    yield put(fetchChartTemplatesRoutine());
  } catch (error) {
    yield showErrorWhenSavedAlert();
  }
}

function* removeChartTemplate({ payload }) {
  const { id } = payload;

  const decision = yield showRemoveDecisionModal();

  if (decision === "confirm") {
    try {
      yield ApiClient.delete({
        urlPath: "app.chartTemplates.remove",
        variables: { id }
      });

      yield showSuccessfullyRemovedAlert();
      yield put(fetchChartTemplatesRoutine());
    } catch (error) {
      yield showErrorWhenSavedAlert();
    }
  } else {
    yield showOperationAbortedAlert();
  }
}

function* fetchChartTemplateDetails({ payload: url }) {
  try {
    const { data } = yield ApiClient.get({
      url,
      skipAuthorizationHeader: true
    });

    const transformedData = toFrontendChartTemplateDetails(data);

    if (typeof transformedData === "undefined") {
      throw Error("Failed transformed data");
    }

    yield put(fetchChartTemplateDetailsRoutine.success(transformedData));
  } catch (e) {
    yield showErrorWhenFetchChartTemplateDetails();
    yield put(fetchChartTemplateDetailsRoutine.failure(e));
  }
}

function* watchChartTemplates() {
  yield takeLatest(fetchChartTemplatesRoutine, fetchChartTemplates);
  yield takeLatest(addChartTemplateRoutine, addChartTemplate);
  yield takeLatest(updateChartTemplateRoutine, updateChartTemplate);
  yield takeLatest(removeChartTemplateRoutine, removeChartTemplate);
  yield takeLatest(fetchChartTemplateDetailsRoutine, fetchChartTemplateDetails);
}

export default [fork(watchChartTemplates)];
