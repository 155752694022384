import PropTypes from "prop-types";
import React from "react";

import { getSectionSlugBySectionId } from "../../../../../lib/getSectionSlugBySectionId";
import { PLAN_PERMISSIONS_SECTIONS } from "../../../../../redux/modules/auth/userPermissions/selector";
import AutomaticTransactionSignal from "../../AutomaticTransactionSignal";
import StockPageSection from "../../StockPageSection";

const TransactionSignalSections = ({
  strategies,
  signals,
  currency,
  stockId,
  userStock,
  preparedSlugList,
  ticker,
  market
}) => {
  return (
    <React.Fragment>
      {strategies.map(strategy => (
        <StockPageSection
          ticker={ticker}
          market={market}
          key={strategy.id}
          title={strategy.name}
          sectionId={strategy.notifications_unique_id}
          isPremium
          sectionPermissions={PLAN_PERMISSIONS_SECTIONS.TRANSACTION_STRATEGY}
          slug={getSectionSlugBySectionId(
            preparedSlugList,
            strategy.notifications_unique_id
          )}
        >
          <AutomaticTransactionSignal
            strategy={strategy}
            signals={signals[strategy.id]}
            currency={currency}
            stockId={stockId}
            userStock={userStock}
          />
        </StockPageSection>
      ))}
    </React.Fragment>
  );
};

TransactionSignalSections.propTypes = {
  strategies: PropTypes.array,
  signals: PropTypes.object,
  stockId: PropTypes.number
};

export default TransactionSignalSections;
