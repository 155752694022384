import React from "react";

import FiltersWrapperAbstract from "../../../../../../../abstract/FiltersWrapperAbstract";
import ApiHelper from "../../../../../../../lib/UniversalTable/CoreHelpers/ApiHelper";
import SingleValueFilter from "./SingleValueFilter";

class ValueFilters extends FiltersWrapperAbstract {
  render() {
    const { columns, settingsState, hasPermission } = this.props;

    return (
      <div className="filters-wrapper">
        {columns
          .filter(column => ApiHelper.getIsFilterableByValueSet(column))
          .filter(column =>
            ApiHelper.getUserHasPermissionToColumn(column, hasPermission)
          )
          .map(column => (
            <SingleValueFilter
              key={ApiHelper.getColumnAttributeForFiltering(column)}
              value={
                settingsState.filters[
                  ApiHelper.getColumnAttributeForFiltering(column)
                ]
              }
              onChange={value => {
                this.applySetting(
                  ApiHelper.getColumnAttributeForFiltering(column),
                  value
                );
              }}
              column={column}
            />
          ))}
      </div>
    );
  }
}

export default ValueFilters;
