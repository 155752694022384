import "./Settings.scss";

import autoBind from "auto-bind";
import React from "react";

import withUserData from "../../../../../../hocs/withUserData";
import accessLevelsMap from "../../../../../../lib/access/accessLevelsMap";
import getAccessLevel from "../../../../../../lib/access/getAccessLevel";
import UniversalTableContext from "../../../../../../lib/UniversalTable/UniversalTableContext";
import SquaberButton from "../../../SquaberButton";
import RangeFilters from "./components/RangeFilters";
import Search from "./components/Search";
import Sorting from "./components/Sorting";
import ValueFilters from "./components/ValueFilters";

@withUserData
class Settings extends React.Component {
  static contextType = UniversalTableContext;

  state = {
    settingsState: null
  };

  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  componentDidMount() {
    const { pagination, sortBy, search, filters } = this.context;

    this.setSettingsState({ pagination, sortBy, search, filters });
  }

  applySettings() {
    const { hideSettings } = this.props;
    let { settingsState } = this.state;

    settingsState = {
      ...settingsState,
      pagination: {
        ...this.context.pagination,
        current: 1
      }
    };

    this.context.setTableState(settingsState, () => {
      this.context.reFetchAfterSettingsChange();
    });

    hideSettings();
  }

  getSettingsState() {
    return this.state.settingsState;
  }

  setSettingsState(newState) {
    this.setState(({ settingsState }) => ({
      settingsState: {
        ...settingsState,
        ...newState
      }
    }));
  }

  render() {
    const { translate, hideSettings, userData, hasPermission } = this.props;
    const settingsState = this.getSettingsState();

    const columns = this.context.getColumns();

    const accessLevel = getAccessLevel(userData, {});
    const userHasAccess = accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS;

    return (
      <div className="universal-table-settings">
        <div
          className="close-settings"
          onClick={() => {
            hideSettings();
          }}
        >
          <i className="fal fa-times" />
        </div>
        {settingsState ? (
          <div className="settings-elements-wrapper">
            <div className="settings-element sorting">
              <h3>{translate("Sorting")}</h3>
              <Sorting
                columns={columns}
                userHasAccess={userHasAccess}
                settingsState={settingsState}
                setSettingsState={this.setSettingsState}
              />
            </div>
            <div className="settings-element">
              <h3>{translate("Filters")}</h3>
              <Search
                columns={columns}
                userHasAccess={userHasAccess}
                hasPermission={hasPermission}
                settingsState={settingsState}
                setSettingsState={this.setSettingsState}
              />
              <RangeFilters
                columns={columns}
                userHasAccess={userHasAccess}
                hasPermission={hasPermission}
                settingsState={settingsState}
                setSettingsState={this.setSettingsState}
              />
              <ValueFilters
                columns={columns}
                userHasAccess={userHasAccess}
                hasPermission={hasPermission}
                settingsState={settingsState}
                setSettingsState={this.setSettingsState}
              />
            </div>
          </div>
        ) : null}
        <div className="apply-settings">
          <SquaberButton
            onClick={() => {
              this.applySettings();
            }}
          >
            {translate("Apply")}
          </SquaberButton>
        </div>
      </div>
    );
  }
}

export default Settings;
