import { all, fork } from "redux-saga/effects";
import { routinePromiseWatcherSaga } from "redux-saga-routines";

import actionHandlerSaga from "./modules/actionHandler/saga";
import affiliateProgramSaga from "./modules/affiliateProgram/saga";
import alertManagementSaga from "./modules/alertManagement/saga";
import alertsSaga from "./modules/alerts/saga";
import analysisCategoriesSaga from "./modules/analysis/analysisCategories/saga";
import stockAnalysisSaga from "./modules/analysis/stockAnalysis/saga";
import deleteSaga from "./modules/auth/delete/saga";
import loginSaga from "./modules/auth/login/saga";
import registerSaga from "./modules/auth/register/saga";
import userPermissionsSaga from "./modules/auth/userPermissions/saga";
import autocompleteSaga from "./modules/autocomplete/saga";
import browserViewSaga from "./modules/browserView/saga";
import chartAnalysesSaga from "./modules/chartAnalyses/saga";
import chartTemplatesSaga from "./modules/chartTemplates/saga";
import configPageSaga from "./modules/configPage/saga";
import contactFormSaga from "./modules/contactForm/saga";
import emailConfirmationSaga from "./modules/emailConfirmation/saga";
import faqSaga from "./modules/faq/saga";
import financialSituationSaga from "./modules/financialSituation/saga";
import followedSaga from "./modules/followed/saga";
import globalConfigSaga from "./modules/globalConfig/saga";
import historyListSaga from "./modules/historyList/saga";
import homeLandingPageSaga from "./modules/homeLandingPage/saga";
import instrumentsListSaga from "./modules/instrumentsList/saga";
import manualStrategiesSaga from "./modules/manualStrategies/saga";
import marketingBarsSaga from "./modules/marketingBars/saga";
import marketingMessagesSaga from "./modules/marketingMessages/saga";
import mediaMonitorSaga from "./modules/mediaMonitor/saga";
import menuSaga from "./modules/menu/saga";
import nativeSceneHandlerSaga from "./modules/nativeSceneHandler/saga";
import notificationsSaga from "./modules/notifications/saga";
import partnershipSaga from "./modules/partnership/saga";
import paymentSaga from "./modules/payment/saga";
import portfoliosSaga from "./modules/portfolios/saga";
import preloadedStockPageData from "./modules/preloadedStockPageData/saga";
import priceAlertsSaga from "./modules/priceAlerts/saga";
import recommendedStocksSaga from "./modules/recommendedStocks/saga";
import reduxSagaTestSaga from "./modules/reduxSagaTest/saga";
import resetPasswordSaga from "./modules/resetPassword/saga";
import routerSaga from "./modules/router/saga";
import salesManagoEventTrackerSaga from "./modules/salesManagoEventTracker/saga";
import searchSaga from "./modules/search/saga";
import stockDataSaga from "./modules/stockData/saga";
import stockPageTvChartSaga from "./modules/stockPageTvChart/saga";
import stockRecommendationsSaga from "./modules/stockRecommendations/saga";
import tagsSaga from "./modules/tags/saga";
import tagsServiceSaga from "./modules/tagsService/saga";
import transactionHistorySaga from "./modules/transactionHistory/saga";
import translationsSaga from "./modules/translations/saga";
import tvchartSaga from "./modules/tvchartData/saga";
import userMarketsSaga from "./modules/userMarkets/saga";
import userMessagesSaga from "./modules/userMessages/saga";
import userNotesSaga from "./modules/userNotes/saga";

export default function* sagas() {
  yield all([
    ...reduxSagaTestSaga,
    ...alertsSaga,
    ...loginSaga,
    ...registerSaga,
    ...menuSaga,
    ...searchSaga,
    ...notificationsSaga,
    ...translationsSaga,
    ...resetPasswordSaga,
    ...followedSaga,
    ...mediaMonitorSaga,
    ...stockDataSaga,
    ...userNotesSaga,
    ...preloadedStockPageData,
    ...priceAlertsSaga,
    ...paymentSaga,
    ...tvchartSaga,
    ...alertManagementSaga,
    ...emailConfirmationSaga,
    ...userMarketsSaga,
    ...transactionHistorySaga,
    ...instrumentsListSaga,
    ...portfoliosSaga,
    ...autocompleteSaga,
    ...marketingBarsSaga,
    ...faqSaga,
    ...configPageSaga,
    ...contactFormSaga,
    ...actionHandlerSaga,
    ...homeLandingPageSaga,
    ...recommendedStocksSaga,
    ...historyListSaga,
    ...routerSaga,
    ...chartTemplatesSaga,
    ...nativeSceneHandlerSaga,
    ...globalConfigSaga,
    ...tagsSaga,
    ...userMessagesSaga,
    ...marketingMessagesSaga,
    ...partnershipSaga,
    ...tagsServiceSaga,
    ...affiliateProgramSaga,
    ...browserViewSaga,
    ...chartAnalysesSaga,
    ...deleteSaga,
    ...stockPageTvChartSaga,
    ...manualStrategiesSaga,
    ...stockRecommendationsSaga,
    ...financialSituationSaga,
    ...analysisCategoriesSaga,
    ...stockAnalysisSaga,
    ...salesManagoEventTrackerSaga,
    ...userPermissionsSaga,
    fork(routinePromiseWatcherSaga)
  ]);
}
