import { isArray, isEmpty } from "lodash";
import moment from "moment-mini";
import {
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading
} from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import { debounceWithPreservingRangeParams } from "../../../lib/debounceWithPreservingRangeParams";
import getUtcTimestamp from "../../../lib/TradingView/getUtcTimestamp";
import SquaberIntervalTypes from "../../../lib/TradingView/SquaberIntervalTypes";
import {
  getHasPermission,
  PLAN_PERMISSIONS_NAMES
} from "../auth/userPermissions/selector";
import { toBackendChartUserData } from "./dto/toBackendChartUserData";
import { toFrontendBackupData } from "./dto/toFrontendBackupData";
import {
  currentMinMaxPointsRoutine,
  getDividendsRoutine,
  getInsiderTransactionsRoutine,
  getPriceAlertsRoutine,
  getReportDatesRoutine,
  lastMinMaxPointsRoutine,
  mediaMonitorEntriesRoutine,
  refreshDividends,
  refreshPriceAlerts,
  refreshReportDates,
  rejectChartUserDataRoutinePromise,
  tvChartGetUserDataRoutine,
  tvChartRoutine,
  tvChartUserDataRoutine
} from "./index";
import { getCurrentLastMinMaxPoints } from "./selector";

function getUnitOfTimeAndPeriodRoundingRule(interval) {
  let result;

  switch (interval) {
    case SquaberIntervalTypes.one_minute:
      result = {
        unitOfTime: "minute",
        shouldUseEndOfPeriodForCurrentTime: true
      };
      break;

    case SquaberIntervalTypes.thirty_minutes:
      result = {
        unitOfTime: "hour",
        shouldUseEndOfPeriodForCurrentTime: true
      };
      break;

    case SquaberIntervalTypes.five_minutes:
    case SquaberIntervalTypes.fifteen_minutes:
    case SquaberIntervalTypes.one_hour:
      result = {
        unitOfTime: "hour",
        shouldUseEndOfPeriodForCurrentTime: false
      };
      break;

    case SquaberIntervalTypes.four_hours:
    case SquaberIntervalTypes.daily:
      result = {
        unitOfTime: "day",
        shouldUseEndOfPeriodForCurrentTime: false
      };
      break;

    case SquaberIntervalTypes.weekly:
      result = {
        unitOfTime: "week",
        shouldUseEndOfPeriodForCurrentTime: true
      };
      break;

    case SquaberIntervalTypes.monthly:
      result = {
        unitOfTime: "month",
        shouldUseEndOfPeriodForCurrentTime: true
      };
      break;

    default:
      result = false;
  }

  return result;
}

const countBackLimit = 10000;

const possibleCountBackValues = new Array(countBackLimit / 500)
  .fill(0)
  .map((value, index) => (index + 1) * 500);

function roundCountBackToNearestPossibleValue(countBack) {
  const indexOfFirstLargerValue = possibleCountBackValues.findIndex(
    value => value > countBack
  );

  return possibleCountBackValues[indexOfFirstLargerValue];
}

function* getMediaMonitorEntries({ payload: { ticker, market, from, to } }) {
  try {
    const mediaMonitorEntries = yield ApiClient.get({
      urlPath: "app.stocks.mediaMonitorForChart",
      variables: {
        ticker,
        market,
        from,
        to
      }
    });

    if (mediaMonitorEntries?.data?.length) {
      yield put(
        mediaMonitorEntriesRoutine.success({
          entries: mediaMonitorEntries.data
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* onGetCurrentMinMaxPoints({ payload: { stockId, interval } }) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.currentLastMinMaxPoints",
      variables: {
        stockId,
        interval
      }
    });

    yield put(currentMinMaxPointsRoutine.success(data));
  } catch (e) {
    console.error(e);
  }
}

function* onGetLastMinMaxPoints({
  payload: { stockId, interval, from, to, count }
}) {
  try {
    let current = yield select(getCurrentLastMinMaxPoints);

    if (isEmpty(current)) {
      yield put(currentMinMaxPointsRoutine.trigger({ stockId, interval }));
      yield take([
        currentMinMaxPointsRoutine.SUCCESS,
        currentMinMaxPointsRoutine.FAILURE
      ]);
    }

    let resultData;

    if (count) {
      const { data } = yield ApiClient.get({
        urlPath: "app.stocks.lastMinMaxPointsCount",
        variables: {
          stockId,
          interval,
          count
        }
      });

      resultData = data;
    } else {
      const { data } = yield ApiClient.get({
        urlPath: "app.stocks.lastMinMaxPoints",
        variables: {
          stockId,
          interval,
          from,
          to
        }
      });

      resultData = data;
    }

    if (!isEmpty(resultData)) {
      yield put(lastMinMaxPointsRoutine.success(resultData));
    }
  } catch (error) {
    console.error(error);
  }
}

function* onGetChartData({
  payload: {
    stockId,
    interval,
    from,
    to,
    shapeManager,
    stock: { ticker, market },
    latestQuoteDatetimeUtc,
    firstDataRequest,
    countBack
  }
}) {
  try {
    const roundingRules = getUnitOfTimeAndPeriodRoundingRule(interval);

    const latestQuoteDatetimeUtcUnix = moment(latestQuoteDatetimeUtc).unix();

    if (from > latestQuoteDatetimeUtcUnix) {
      from = latestQuoteDatetimeUtcUnix;
    }

    if (roundingRules) {
      const { unitOfTime } = roundingRules;

      from = moment
        .unix(from)
        .endOf(unitOfTime)
        .unix();

      to = moment
        .unix(to)
        .endOf(unitOfTime)
        .unix();
    }

    if (from < 1) {
      from = 1;
    }

    if (to < 1) {
      to = 1;
    }

    let result;
    let countBackUsed = false;

    if (firstDataRequest && countBack && countBack < countBackLimit) {
      result = yield ApiClient.get({
        urlPath: "app.stocks.chartDataCountBack",
        variables: {
          stockId,
          interval,
          count: roundCountBackToNearestPossibleValue(countBack)
        }
      });
      countBackUsed = true;
    } else {
      result = yield ApiClient.get({
        urlPath: "app.stocks.chartData",
        variables: {
          stockId,
          interval,
          from,
          to
        }
      });
    }

    let quotations = [];

    let i = 0;
    for (let quote of result.data.quotes) {
      let time = new Date(quote.datetime_utc);
      let utcTimestamp = getUtcTimestamp(time);

      let transformedQuote = {
        time: utcTimestamp
      };

      for (let item of ["open", "high", "low", "close", "volume"]) {
        transformedQuote[item] = Number(quote[item]);
      }

      quotations.push(transformedQuote);

      i += 1;
    }

    shapeManager.manage(result.data, interval);

    yield put(tvChartRoutine.success(quotations));

    if ([SquaberIntervalTypes.daily].includes(interval)) {
      yield put(
        lastMinMaxPointsRoutine.trigger({
          stockId,
          interval,
          from,
          to,
          count: countBackUsed
            ? roundCountBackToNearestPossibleValue(countBack)
            : null
        })
      );

      yield put(
        getInsiderTransactionsRoutine.trigger({
          stockId,
          from,
          to,
          count: countBackUsed
            ? roundCountBackToNearestPossibleValue(countBack)
            : null
        })
      );
    }

    if (
      [SquaberIntervalTypes.daily, SquaberIntervalTypes.weekly].includes(
        interval
      )
    ) {
      yield put(
        mediaMonitorEntriesRoutine.trigger({
          ticker,
          market,
          from,
          to
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(tvChartRoutine.failure(error));
  }
}

function* onSetChartUserData({
  payload: { stockId, chart, additionalData = {} }
}) {
  try {
    let { data } = yield ApiClient.post({
      urlPath: "app.stocks.userChartData",
      variables: { stockId },
      data: toBackendChartUserData({ chart, additionalData })
    });

    yield put(tvChartUserDataRoutine.success(data));
  } catch (error) {
    console.error(error);
    yield put(tvChartUserDataRoutine.failure());
  }
}

function* fetchBackupData(backupUrl) {
  try {
    if (typeof backupUrl !== "string") {
      throw Error("fetchBackup param needs to be string");
    }

    const { data } = yield ApiClient.get({
      url: backupUrl,
      skipAuthorizationHeader: true
    });

    const backupData = toFrontendBackupData(data);

    if (typeof backupData === "undefined") {
      throw Error("Failed backup data transformation");
    }

    return backupData;
  } catch (error) {
    console.error(error);
  }
}

function* onGetChartUserData({ payload: stockId }) {
  try {
    const hasPermission = yield select(getHasPermission);

    if (!hasPermission([PLAN_PERMISSIONS_NAMES.TRADING_VIEW_CHART])) {
      yield put(tvChartGetUserDataRoutine.success(false));
      return;
    }

    let { data } = yield ApiClient.get({
      urlPath: "app.stocks.userChartData",
      variables: { stockId },
      skipAccessCheck: true
    });

    const latestBackup = yield fetchBackupData(data.backups[0].url);

    let mergedData = { ...data };

    if (latestBackup) {
      mergedData = { ...latestBackup };
    }

    yield put(tvChartGetUserDataRoutine.success(mergedData));
  } catch (error) {
    yield put(tvChartGetUserDataRoutine.success(false));
  }
}

function* onRejectGetChartUserData() {
  yield put(tvChartGetUserDataRoutine.failure());
}

function* onGetReportDates({ payload: stockId }) {
  try {
    if (typeof stockId === "undefined") return;

    const { data: reportDates } = yield ApiClient.get({
      urlPath: "app.stocks.reportDates",
      variables: {
        stockId
      }
    });
    yield put(getReportDatesRoutine.success(reportDates));
  } catch (e) {
    yield put(getReportDatesRoutine.failure(e));
    console.error(e);
  }
}

function* onGetDividends({ payload: stockId }) {
  try {
    if (typeof stockId === "undefined") {
      throw Error("Missing stockId");
    }

    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.dividends",
      variables: {
        stockId
      }
    });

    yield put(getDividendsRoutine.success(data));
  } catch (e) {
    yield put(getDividendsRoutine.failure(e));
    console.error(e);
  }
}

function* onGetPriceAlerts({ payload: stockId }) {
  try {
    if (typeof stockId === "undefined") {
      throw Error("Missing stockId");
    }

    const { data } = yield ApiClient.get({
      urlPath: "app.priceAlerts.get",
      variables: {
        stockId
      },
      skipAccessCheck: true
    });

    if (!isArray(data?.alerts)) {
      throw Error("Provided alerts not match array type");
    }

    yield put(getPriceAlertsRoutine.success(data.alerts));
  } catch (e) {
    yield put(getPriceAlertsRoutine.failure(e));
    console.error(e);
  }
}

function* onGetInsiderTransactions({ payload: { stockId, from, to, count } }) {
  let urlPath = "app.stocks.insiderTransactionsMarkers";
  let variables = {
    stockId
  };

  if (count) {
    urlPath = "app.stocks.insiderTransactionsMarkersCount";
    variables = {
      ...variables,
      count
    };
  } else {
    variables = {
      ...variables,
      from,
      to
    };
  }

  try {
    const { data } = yield ApiClient.get({
      urlPath,
      variables
    });

    yield put(getInsiderTransactionsRoutine.success(data));
  } catch (e) {
    console.error(e);
  }
}

function* watchGetChartData() {
  yield takeEvery(tvChartRoutine.TRIGGER, onGetChartData);
  yield takeLatest(getReportDatesRoutine.TRIGGER, onGetReportDates);
  yield takeEvery(tvChartUserDataRoutine.TRIGGER, onSetChartUserData);
  yield takeLatest(tvChartGetUserDataRoutine.TRIGGER, onGetChartUserData);
  yield takeLatest(rejectChartUserDataRoutinePromise, onRejectGetChartUserData);
  yield takeEvery(
    getInsiderTransactionsRoutine.TRIGGER,
    onGetInsiderTransactions
  );
  yield takeEvery(lastMinMaxPointsRoutine, onGetLastMinMaxPoints);
  yield takeLeading(currentMinMaxPointsRoutine, onGetCurrentMinMaxPoints);
  yield debounceWithPreservingRangeParams(
    1000,
    mediaMonitorEntriesRoutine.TRIGGER,
    getMediaMonitorEntries
  );
  yield takeLatest(getDividendsRoutine, onGetDividends);
  yield takeLatest(getPriceAlertsRoutine, onGetPriceAlerts);
  yield takeLatest(refreshReportDates, onGetReportDates);
  yield takeLatest(refreshDividends, onGetDividends);
  yield takeLatest(refreshPriceAlerts, onGetPriceAlerts);
}

export default [fork(watchGetChartData)];
